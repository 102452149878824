import React, { useState, useRef } from "react";import LazyImage from "../LazyImage/LazyImage";
import { NavLink } from "react-router-dom";
import * as motion from "motion/react-client";
  import logo from "../Assets/imcs.png";
import menu from "../Assets/para.png";
import arro from "../Assets/arro.png";
 import cancel from "../Assets/cancels.png";
import soundFile from "../Assets/beep.wav";
import soundFile2 from "../Assets/chip.wav";
export default function Navigation() {
  const audioRef = useRef(new Audio(soundFile));
  const audioRefs = useRef(new Audio(soundFile2));
  const [menus, setMenu] = useState(false);
 
  const playSound = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  const createMenu = () => {
    audioRefs.current.currentTime = 0;
    audioRefs.current.play();
    var flag = !menus;
    setMenu(flag);
  };

  return (
    <div className="header">
      <div className="menubar">
        {menus && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.4,
              scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
            }}
            className="allmenu"
          >
            <NavLink to="/home" className="menux" onClick={playSound}>
              <div className="circle"></div>
              <p>Home</p>
              <img className="arrows" src={arro} alt="#" loading="lazy"/>
              <div className="popup">
                <div className="circular"></div>
              </div>
            </NavLink>
            <NavLink to="/about" className="menuxs" onClick={playSound}>
              <div className="circles"></div>
              <p>About Us</p>
              <img className="arrows" src={arro} alt="#" loading="lazy"/>
              <div className="popup">
                <div className="circular"></div>
              </div>
            </NavLink>
            <NavLink to="/marketing" className="menuxs" onClick={playSound}>
              <div className="circles"></div>
              <p>Marketing</p>
              <img className="arrows" src={arro} alt="#" loading="lazy"/>
              <div className="popup">
                <div className="circular"></div>
              </div>
            </NavLink>
            <NavLink to="/media" className="menuxs" onClick={playSound}>
              <div className="circles"></div>
              <p>Multi-Media</p>
              <img className="arrows" src={arro} alt="#" loading="lazy"/>
              <div className="popup" onClick={playSound}>
                <div className="circular"></div>
              </div>
            </NavLink>
            <NavLink to="/fabrication" className="menuxs" onClick={playSound}>
              <div className="circles"></div>
              <p>Fabrication</p>
              <img className="arrows" src={arro} alt="#" loading="lazy"/>
              <div className="popup">
                <div className="circular"></div>
              </div>
            </NavLink>

            {/* <NavLink to="/hybrid-marketing" className="menuxs" onClick={playSound}>
              <div className="circles"></div>
              <p>Hybrid Marketing</p>
              <img className="arrows" src={arro} alt="#" />
              <div className="popup">
                <div className="circular"></div>
              </div>
            </NavLink> */}
            {/* <NavLink to="/experiential" className="menuxs" onClick={playSound}>
              <div className="circles"></div>
              <p>Experiential Marketing</p>
              <img className="arrows" src={arro} alt="#" />
              <div className="popup">
                <div className="circular"></div>
              </div>
            </NavLink> */}
          
            <NavLink to="/contacts" className="menuxs" onClick={playSound}>
              <div className="circles"></div>
              <p>Contact Us</p>
              <img className="arrows" src={arro} alt="#" />
              <div className="popup">
                <div className="circular"></div>
              </div>
            </NavLink>
            
            <div className="lineerases"></div>
            <div className="cuts"></div>
          </motion.div>
        )}
        {!menus && (
          <img onClick={createMenu} className="menuimg" src={menu} alt="#" loading="lazy"/>
        )}
        {menus && (
          <img onClick={createMenu} className="menuimg" src={cancel} alt="#" loading="lazy"/>
        )}
        {/* {!menus && <div className="lineerase"></div>} */}
      </div>
      <div className="logo">
        <img src={logo} alt="#" loading="lazy"/>
      </div>
      <div className="rightlg"></div>
    </div>
  );
}

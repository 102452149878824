/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 ./public/model.glb 
Author: EradifyerAO (https://sketchfab.com/eradifyerao)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/modular-corner-cooridor-hallway-06cdcb9dddeb4c299e1725250bb00bb6
Title: Modular corner cooridor hallway
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/model.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <group position={[4381.631, 78.329, 330.516]}>
          <mesh geometry={nodes.Cube019_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_001008_decals_0.geometry} material={materials.decals} position={[-94.691, -32.465, 32.924]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} />
          <mesh geometry={nodes.Santus_scifi_183_Decal_183_decals_0.geometry} material={materials.decals} position={[-96.174, 50.69, -14.349]} rotation={[1.57, 0, Math.PI / 2]} />
        </group>
        <group position={[4381.631, 78.329, 789.385]} rotation={[-Math.PI, 0, Math.PI]} scale={[-1, 1, 1]}>
          <mesh geometry={nodes.Cube021_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Santus_scifi_183_Decal_183001_decals_0.geometry} material={materials.decals} position={[-96.174, 51.313, -13.502]} rotation={[1.572, 0, Math.PI / 2]} />
        </group>
        <group position={[4592.203, 462, 210.572]}>
          <group position={[40.733, 24.665, -37.487]} rotation={[-Math.PI, -Math.PI / 4, 0]}>
            <mesh geometry={nodes['B��zierCurve003_Material007_0'].geometry} material={materials['Material.007']} />
            <mesh geometry={nodes['B��zierCurve005_Material007_0'].geometry} material={materials['Material.007']} position={[-55.574, 25.247, 2.166]} rotation={[0.955, -Math.PI / 6, -0.955]} scale={0.01} />
          </group>
          <mesh geometry={nodes.Plane017_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes['B��zierCurve004_Material007_0'].geometry} material={materials['Material.007']} position={[60.032, 28, -10.095]} rotation={[-Math.PI, -Math.PI / 4, 0]} />
          <mesh geometry={nodes.Example_Sheet_008001_decals_0.geometry} material={materials.decals} position={[-42.789, 77.203, -12.284]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.NurbsPath005_Material007_0.geometry} material={materials['Material.007']} position={[-111.3, 28, 58.453]} scale={[0.496, 1, 1]} />
        </group>
        <group position={[5018.514, 462, 578.246]}>
          <mesh geometry={nodes.Plane019_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_008006_decals_0.geometry} material={materials.decals} position={[-44.469, 75.467, -6.645]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.Plane276_Material007_0.geometry} material={materials['Material.007']} position={[100.191, 78.17, -9.861]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-1, 1, 1]} />
          <mesh geometry={nodes.Santus_scifi_274_Decal_274003_projected_decals_0.geometry} material={materials.decals} position={[216.307, -45.94, -285.087]} />
        </group>
        <group position={[4865.359, 569.155, 483.728]}>
          <mesh geometry={nodes.Plane020_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036007_decals_0.geometry} material={materials.decals} position={[-103.321, -19.155, 31.901]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[4717.698, 569.155, 336.067]}>
          <mesh geometry={nodes.Plane041_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036004_decals_0.geometry} material={materials.decals} position={[-45.762, -19.584, -2.142]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[4795.499, 580, 413.868]}>
          <mesh geometry={nodes.Plane043_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_047002_decals_0.geometry} material={materials.decals} position={[-8.253, -9.04, 14.517]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
          <mesh geometry={nodes.Example_Sheet_047003_decals_0.geometry} material={materials.decals} position={[11.954, 2.571, -45.97]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
        </group>
        <group position={[4081.631, 566.667, 700.561]}>
          <mesh geometry={nodes.Plane047_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_020_decals_0.geometry} material={materials.decals} position={[-278.167, -16.667, -3.656]} />
        </group>
        <group position={[4081.631, 566.667, 459.894]}>
          <mesh geometry={nodes.Plane048_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036011_decals_0.geometry} material={materials.decals} position={[-9.02, -17.206, -27.453]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[4081.631, 580, 580.227]}>
          <group position={[0.001, -19.219, 0]} scale={[1, 1, 0.72]}>
            <mesh geometry={nodes.Plane277_base_trim_0.geometry} material={materials.base_trim} />
            <mesh geometry={nodes.MyDecals_021_LONG_Light_decals_0.geometry} material={materials.decals} position={[-0.001, 19.219, -0.363]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[0.209, 0.29, 0.215]} />
            <mesh geometry={nodes.MyDecals_021_LONG_Light001_decals_0.geometry} material={materials.decals} position={[-0.001, 19.514, -0.227]} rotation={[-2.579, 1.404, 1.91]} scale={[-0.009, 0.012, 0.215]} />
          </group>
          <mesh geometry={nodes.Plane050_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_047006_decals_0.geometry} material={materials.decals} position={[-9.948, -10.795, -46.569]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
          <mesh geometry={nodes.Example_Sheet_047007_decals_0.geometry} material={materials.decals} position={[-10.25, -10.878, 47.842]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
        </group>
        <group position={[3681.631, 566.667, 700.561]}>
          <mesh geometry={nodes.Plane053_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036009_decals_0.geometry} material={materials.decals} position={[-1.228, -17.204, 29.035]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[3681.631, 566.667, 459.894]}>
          <mesh geometry={nodes.Plane054_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036008_decals_0.geometry} material={materials.decals} position={[-0.644, -17.273, -27.453]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[3681.631, 580, 580.227]}>
          <mesh geometry={nodes.Plane056_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_047008_decals_0.geometry} material={materials.decals} position={[13.192, -10.795, -45.378]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
          <mesh geometry={nodes.Example_Sheet_047009_decals_0.geometry} material={materials.decals} position={[12.889, -10.878, 48.46]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
        </group>
        <group position={[3681.631, -5, 580.227]}>
          <mesh geometry={nodes.Plane057_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_047011_decals_0.geometry} material={materials.decals} position={[2.521, 15.166, 1.163]} rotation={[0, -Math.PI / 2, 0]} scale={0.599} />
        </group>
        <group position={[5082.192, 566.667, -200]}>
          <mesh geometry={nodes.Plane059_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036006_decals_0.geometry} material={materials.decals} position={[28.705, -17.149, -5.324]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[4841.525, 566.667, -200]}>
          <mesh geometry={nodes.Plane060_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036005_decals_0.geometry} material={materials.decals} position={[-27.785, -17.148, -5.489]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[4961.859, 580, -200]}>
          <mesh geometry={nodes.Plane062_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_047004_decals_0.geometry} material={materials.decals} position={[45.946, -10.806, 6.162]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
          <mesh geometry={nodes.Example_Sheet_047005_decals_0.geometry} material={materials.decals} position={[-44.667, -10.774, 5.603]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
        </group>
        <group position={[4961.859, -5, -180]}>
          <mesh geometry={nodes.Plane063_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes['INSTANT_038_8778ff82-d085-4172-9336-9d991f648bf0_decals_0'].geometry} material={materials.decals} rotation={[1.41, -0.273, 1.029]} />
          <mesh geometry={nodes['INSTANT_038_8778ff82-d085-4172-9336-9d991f648bf0001_decals_0'].geometry} material={materials.decals} rotation={[1.41, -0.273, 1.029]} />
        </group>
        <group position={[4594.011, 178.194, 212.38]}>
          <group position={[10.59, -136.475, 10.59]}>
            <mesh geometry={nodes.Plane066_Material007_0.geometry} material={materials['Material.007']} />
            <mesh geometry={nodes.Plane287_Material007_0.geometry} material={materials['Material.007']} position={[-10.484, 136.371, -11.953]} rotation={[1.582, -0.005, -0.004]} scale={0.01} />
          </group>
          <mesh geometry={nodes.Plane065_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Cube025_base_trim_0.geometry} material={materials.base_trim} position={[52.118, 78.029, -204.996]} rotation={[0, Math.PI / 2, 0]} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation001_decals_0.geometry} material={materials.decals} position={[11.471, 125.758, -36.284]} />
          <mesh geometry={nodes.Plane281_Material007_0.geometry} material={materials['Material.007']} position={[30.771, -45.892, -44.445]} />
          <mesh geometry={nodes.TrimSheet_002005_decals_0.geometry} material={materials.decals} position={[753.528, 36.303, 9.355]} rotation={[Math.PI / 2, 0, -3.141]} />
          <mesh geometry={nodes.TrimSheet_002012_decals_0.geometry} material={materials.decals} position={[-12.296, 198.108, -36.379]} rotation={[Math.PI / 2, 0, -3.141]} />
        </group>
        <group position={[4081.631, 462, 240]}>
          <mesh geometry={nodes.Plane067_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_008005_decals_0.geometry} material={materials.decals} position={[-4.832, 77.203, 20.41]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.NurbsPath004_Material007_0.geometry} material={materials['Material.007']} position={[-200.718, 28, 29.024]} scale={[0.496, 1, 1]} />
          <mesh geometry={nodes.Santus_scifi_274_Decal_274001_decals_0.geometry} material={materials.decals} position={[-196.589, -38.912, 20]} rotation={[Math.PI / 2, -0.005, 0]} scale={[-1, 1, 1]} />
        </group>
        <group position={[4081.631, 178.194, 242.917]}>
          <group position={[0, -136.475, 17.083]}>
            <mesh geometry={nodes.Plane069_Material007_0.geometry} material={materials['Material.007']} />
            <mesh geometry={nodes.Aircraft_025002_decals_0.geometry} material={materials.decals} position={[1.452, 136.18, -18.346]} rotation={[2.34, 0, 0]} scale={[-0.01, 0.01, 0.01]} />
          </group>
          <mesh geometry={nodes.Plane068_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Cube018_base_trim_0.geometry} material={materials.base_trim} position={[-121.18, 75.53, 21.199]} />
          <mesh geometry={nodes.Cube023_base_trim_0.geometry} material={materials.base_trim} position={[-296.114, 75.53, 616.185]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube049_decals_0.geometry} material={materials.decals} position={[-121.18, 75.53, 21.199]} />
          <mesh geometry={nodes.Cube050_decals_0.geometry} material={materials.decals} position={[-296.114, 75.53, 616.214]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube051_decals_0.geometry} material={materials.decals} position={[-296.114, 75.53, 616.378]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube052_decals_0.geometry} material={materials.decals} position={[-296.114, 75.53, 616.72]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube053_decals_0.geometry} material={materials.decals} position={[-296.114, 75.53, 616.102]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube054_decals_0.geometry} material={materials.decals} position={[-296.114, 75.53, 616.224]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube058_decals_0.geometry} material={materials.decals} position={[-121.18, 75.53, 21.737]} />
          <mesh geometry={nodes.Cube059_decals_0.geometry} material={materials.decals} position={[-121.18, 75.53, 21.199]} />
          <mesh geometry={nodes.Cube060_decals_0.geometry} material={materials.decals} position={[-121.18, 75.53, 21.247]} />
          <mesh geometry={nodes.Example_Sheet_033001_decals_0.geometry} material={materials.decals} position={[-189.174, 151.48, 17.083]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation003_decals_0.geometry} material={materials.decals} position={[-279.894, 125.705, 16.998]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={4.273} />
          <mesh geometry={nodes.NurbsPath023_Material007_0.geometry} material={materials['Material.007']} position={[-190.742, -48.333, 8.629]} />
          <mesh geometry={nodes.Santus_scifi_330_Decal_330_decals_0.geometry} material={materials.decals} position={[3.642, -90.416, -2.917]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.TrimSheet_002001_decals_0.geometry} material={materials.decals} position={[-111.882, 198.018, -2.917]} rotation={[Math.PI / 2, 0, 0]} scale={1.881} />
          <mesh geometry={nodes.TrimSheet_002002_decals_0.geometry} material={materials.decals} position={[1220.437, 198.108, -511.017]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={1.881} />
          <mesh geometry={nodes.TrimSheet_002003_decals_0.geometry} material={materials.decals} position={[142.59, 198.018, -2.917]} rotation={[Math.PI / 2, 0, 0]} scale={1.881} />
        </group>
        <group position={[3681.631, 462, 240]}>
          <mesh geometry={nodes.Plane070_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_008002_decals_0.geometry} material={materials.decals} position={[1083.987, -84.602, -41.338]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.Plane100_Material007_0.geometry} material={materials['Material.007']} position={[1.222, 26.148, 29.019]} />
          <mesh geometry={nodes.Santus_scifi_274_Decal_274006_decals_0.geometry} material={materials.decals} position={[2.813, -37.928, 20]} rotation={[Math.PI / 2, -0.005, 0]} scale={[-1, 1, 1]} />
        </group>
        <group position={[3681.631, 178.194, 242.917]}>
          <mesh geometry={nodes.Plane071_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Cube016_decals_0.geometry} material={materials.decals} position={[-13.558, 78.029, 19.822]} />
          <mesh geometry={nodes.Cube024_decals_0.geometry} material={materials.decals} position={[396.264, 78.029, 617.562]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube026_decals_0.geometry} material={materials.decals} position={[298.614, 75.53, 319.983]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube038_decals_0.geometry} material={materials.decals} position={[298.614, 75.53, 319.983]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh geometry={nodes.Cube047_decals_0.geometry} material={materials.decals} position={[84.093, 75.53, 317.4]} />
          <mesh geometry={nodes.Cube048_decals_0.geometry} material={materials.decals} position={[84.093, 75.53, 317.4]} />
          <mesh geometry={nodes.Example_Sheet_033_decals_0.geometry} material={materials.decals} position={[-71.143, 151.48, 17.083]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation005_decals_0.geometry} material={materials.decals} position={[120.106, 125.705, 16.998]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={4.273} />
          <mesh geometry={nodes.NurbsPath028_Material007_0.geometry} material={materials['Material.007']} position={[0, -98.194, 8.629]} />
          <mesh geometry={nodes.Plane072_Material007_0.geometry} material={materials['Material.007']} position={[0, -136.475, 17.083]} />
          <mesh geometry={nodes.Santus_scifi_343_Decal_343_decals_0.geometry} material={materials.decals} position={[2.987, 9.59, 19.211]} rotation={[Math.PI / 2, 0, 0]} scale={1.66} />
          <mesh geometry={nodes.TrimSheet_002_decals_0.geometry} material={materials.decals} position={[0, 198.063, -2.949]} rotation={[Math.PI / 2, 0, 0]} scale={1.904} />
        </group>
        <group position={[4621.632, 462, -180]}>
          <mesh geometry={nodes.Plane073_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_008007_decals_0.geometry} material={materials.decals} position={[23.045, 77.203, 0.641]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.NurbsPath013_Material007_0.geometry} material={materials['Material.007']} position={[31.096, 26.11, 1.91]} rotation={[0, Math.PI / 2, 0]} scale={[0.496, 1, 1]} />
        </group>
        <group position={[4624.548, 178.194, -180]}>
          <group position={[21.581, 78.029, 24.747]} rotation={[0, Math.PI / 2, 0]}>
            <mesh geometry={nodes.Cube028_Examples_020002_0.geometry} material={materials['.Examples_020.002']} />
            <mesh geometry={nodes.Cube028_Santus_scifi_305_Decal_305_0.geometry} material={materials['.Santus_scifi_305_Decal_305']} />
            <mesh geometry={nodes.Cube028_Example_Sheet_026_0.geometry} material={materials['.Example_Sheet_026']} />
          </group>
          <mesh geometry={nodes.Plane074_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation009_decals_0.geometry} material={materials.decals} position={[501.149, -36.048, 384.641]} />
          <mesh geometry={nodes.Plane075_Material007_0.geometry} material={materials['Material.007']} position={[17.083, -136.475, 0]} />
          <mesh geometry={nodes.Plane127_Material007_0.geometry} material={materials['Material.007']} position={[17.258, -86.467, 151.751]} />
          <mesh geometry={nodes.TrimSheet_002010_decals_0.geometry} material={materials.decals} position={[-2.936, 198.108, -88.1]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={1.881} />
        </group>
        <group position={[4081.209, 580, 800.227]}>
          <mesh geometry={nodes.Plane081_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_036010_decals_0.geometry} material={materials.decals} position={[-8.56, -32.361, -70.632]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        </group>
        <group position={[4081.631, -30, 360.227]}>
          <mesh geometry={nodes.Plane083_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Santus_scifi_301_Decal_301001_decals_0.geometry} material={materials.decals} position={[-111.909, 10, 34.571]} rotation={[0, -1.571, 0]} scale={0.645} />
        </group>
        <group position={[4081.631, -30, 560.682]}>
          <mesh geometry={nodes.Plane085_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_001011_decals_0.geometry} material={materials.decals} position={[-0.543, 10.144, -62.008]} scale={[-1, 1, 1]} />
          <mesh geometry={nodes.Example_Sheet_036001_decals_0.geometry} material={materials.decals} position={[-271.708, 10, -82.211]} rotation={[0, -Math.PI / 2, 0]} />
        </group>
        <group position={[4081.631, -30, 767.915]}>
          <mesh geometry={nodes.Plane086_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_042006_decals_0.geometry} material={materials.decals} position={[-10.344, 11.005, -63.093]} />
          <mesh geometry={nodes.Example_Sheet_042007_decals_0.geometry} material={materials.decals} position={[-426.124, 11.005, -62.743]} />
          <mesh geometry={nodes.Example_Sheet_042023_decals_0.geometry} material={materials.decals} position={[-426.124, 11.005, -352.154]} />
          <mesh geometry={nodes.Santus_scifi_301_Decal_301_decals_0.geometry} material={materials.decals} position={[-111.909, 10, -40.326]} rotation={[0, -1.571, 0]} scale={0.645} />
        </group>
        <group position={[3681.631, -30, 360.227]}>
          <mesh geometry={nodes.Plane089_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_001009_decals_0.geometry} material={materials.decals} position={[3.734, 10.02, -34.642]} rotation={[Math.PI, 0, Math.PI]} />
          <mesh geometry={nodes.Example_Sheet_001012_decals_0.geometry} material={materials.decals} position={[1.401, 10.028, 435.339]} scale={[-1, 1, 1]} />
          <mesh geometry={nodes.Example_Sheet_045004_decals_0.geometry} material={materials.decals} position={[-74.786, 10.194, -8.224]} rotation={[0, -Math.PI / 2, 0]} />
        </group>
        <group position={[3681.631, -30, 560.682]}>
          <mesh geometry={nodes.Plane091_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Panels_007002_decals_0.geometry} material={materials.decals} position={[3.632, 10.191, 93.255]} scale={[5.422, 1, 1]} />
          <mesh geometry={nodes.Example_Panels_007018_decals_0.geometry} material={materials.decals} position={[3.632, 10.191, -54.146]} scale={[5.422, 1, 1]} />
          <mesh geometry={nodes.Example_Sheet_001004_decals_0.geometry} material={materials.decals} position={[0.537, 10.16, -61.962]} scale={[-1, 1, 1]} />
          <mesh geometry={nodes.Example_Sheet_036_decals_0.geometry} material={materials.decals} position={[-74.337, 10, -82.211]} rotation={[0, -Math.PI / 2, 0]} />
        </group>
        <group position={[3681.631, -30, 761.137]}>
          <mesh geometry={nodes.Plane092_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_001002_decals_0.geometry} material={materials.decals} position={[0.42, 10.16, -95.298]} rotation={[Math.PI, 0, Math.PI]} />
          <mesh geometry={nodes.Example_Sheet_045002_decals_0.geometry} material={materials.decals} position={[-74.786, 10.02, 8.224]} rotation={[0, -Math.PI / 2, 0]} />
        </group>
        <group position={[4741.859, -30, -180]}>
          <mesh geometry={nodes.Plane095_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_042005_decals_0.geometry} material={materials.decals} position={[53.465, 11.151, -16.551]} rotation={[Math.PI, 0, -Math.PI]} scale={[-1, 1, 1]} />
        </group>
        <group position={[5003.762, 178.194, 576.438]}>
          <mesh geometry={nodes.Plane099_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation004_decals_0.geometry} material={materials.decals} position={[230.908, 177.143, 284.627]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[-4.273, 4.273, 4.273]} />
          <mesh geometry={nodes.Santus_scifi_299_Decal_299_projected_decals_0.geometry} material={materials.decals} position={[205.321, 55.731, -145.837]} />
          <mesh geometry={nodes.TrimSheet_002006_decals_0.geometry} material={materials.decals} position={[111.661, 250.018, 21.066]} rotation={[Math.PI / 2, 0, 0]} scale={1.881} />
        </group>
        <group position={[4081.631, 462, 881.039]}>
          <mesh geometry={nodes.Plane101_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_008004_decals_0.geometry} material={materials.decals} position={[337.556, 127.498, -305.376]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.NurbsPath020_Material007_0.geometry} material={materials['Material.007']} position={[-204.034, 26.126, -29.849]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.496, 1, 1]} />
          <mesh geometry={nodes.Santus_scifi_274_Decal_274_decals_0.geometry} material={materials.decals} position={[-199.732, -40.502, -20]} rotation={[Math.PI / 2, 0, -Math.PI]} />
          <mesh geometry={nodes.Santus_scifi_274_Decal_274005_decals_0.geometry} material={materials.decals} position={[171.529, -40.502, -20]} rotation={[Math.PI / 2, 0, -Math.PI]} />
        </group>
        <group position={[4081.631, 178.194, 878.448]}>
          <mesh geometry={nodes.Plane102_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_033003_decals_0.geometry} material={materials.decals} position={[-189.174, 151.48, -17.083]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation006_decals_0.geometry} material={materials.decals} position={[26.233, 125.143, -17.682]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[-4.273, 4.273, 4.273]} />
          <mesh geometry={nodes.NurbsPath029_Material007_0.geometry} material={materials['Material.007']} position={[16.382, -44.084, -9.325]} />
          <mesh geometry={nodes.Plane103_Material007_0.geometry} material={materials['Material.007']} position={[-30, -136.687, -18.719]} rotation={[0.96, 0, 0]} />
          <mesh geometry={nodes.TrimSheet_002007_decals_0.geometry} material={materials.decals} position={[-111.882, 198.018, 2.183]} rotation={[Math.PI / 2, 0, 0]} scale={1.881} />
          <mesh geometry={nodes.TrimSheet_002008_decals_0.geometry} material={materials.decals} position={[142.59, 198.018, 2.183]} rotation={[Math.PI / 2, 0, 0]} scale={1.881} />
        </group>
        <group position={[3681.631, 462, 881.039]}>
          <mesh geometry={nodes.Plane104_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_008003_decals_0.geometry} material={materials.decals} position={[-1.599, 75.497, -21.142]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.Plane293_Material007_0.geometry} material={materials['Material.007']} position={[1.201, 26.105, -29.828]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-1, 1, 1]} />
          <mesh geometry={nodes.Santus_scifi_274_Decal_274007_decals_0.geometry} material={materials.decals} position={[2.813, -40.026, -20.04]} rotation={[Math.PI / 2, -0.005, Math.PI]} />
        </group>
        <group position={[3681.631, 178.194, 878.448]}>
          <group position={[0, -136.685, -18.717]}>
            <mesh geometry={nodes.Plane106_Material007_0.geometry} material={materials['Material.007']} />
            <mesh geometry={nodes.Plane266_Material007_0.geometry} material={materials['Material.007']} position={[0.971, 136.871, 17.353]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={0.011} />
          </group>
          <mesh geometry={nodes.Plane105_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_033002_decals_0.geometry} material={materials.decals} position={[-53.952, 151.48, -17.083]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[-1, 1, 1]} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation007_decals_0.geometry} material={materials.decals} position={[-79.894, 125.142, -17.382]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[-4.273, 4.273, 4.273]} />
          <mesh geometry={nodes.NurbsPath027_Material007_0.geometry} material={materials['Material.007']} position={[0, -98.194, -626.902]} />
          <mesh geometry={nodes.Santus_scifi_343_Decal_343001_decals_0.geometry} material={materials.decals} position={[2.987, 9.59, -17.812]} rotation={[Math.PI / 2, 0, 0]} />
        </group>
        <group position={[5302.086, 462, -238.951]}>
          <mesh geometry={nodes.Plane107_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.Example_Sheet_008_decals_0.geometry} material={materials.decals} position={[-22.265, 75.497, 57.163]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.NurbsPath021_Material007_0.geometry} material={materials['Material.007']} position={[-27.54, 26.11, 62.559]} rotation={[0, -Math.PI / 2, 0]} scale={[-0.496, 1, 1]} />
        </group>
        <group position={[5299.169, 178.194, -238.951]}>
          <group position={[-20.796, 78.029, 50.978]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh geometry={nodes.Cube027_Examples_020002_0.geometry} material={materials['.Examples_020.002']} />
            <mesh geometry={nodes.Cube027_Santus_scifi_305_Decal_305_0.geometry} material={materials['.Santus_scifi_305_Decal_305']} />
            <mesh geometry={nodes.Cube027_Example_Sheet_026_0.geometry} material={materials['.Example_Sheet_026']} />
          </group>
          <mesh geometry={nodes.Plane108_base_trim_0.geometry} material={materials.base_trim} />
          <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation008_decals_0.geometry} material={materials.decals} position={[-20.213, 125.143, 37.025]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[-4.273, 4.273, 4.273]} />
          <mesh geometry={nodes.Plane138_Material007_0.geometry} material={materials['Material.007']} position={[-10.452, -88.296, 96.19]} />
        </group>
        <mesh geometry={nodes.Aircraft_025001_decals_0.geometry} material={materials.decals} position={[4226.893, 51.941, 845.493]} rotation={[2.372, 0, 0]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes.Aircraft_025005_decals_0.geometry} material={materials.decals} position={[4226.893, 51.941, 272.412]} rotation={[0.769, 0, 0]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes.Cube020__0.geometry} material={materials['Cube.020__0']} position={[4369.796, -9.019, 213.809]} scale={[0.945, 0.771, 0.771]} />
        <mesh geometry={nodes.Cube035_base_trim_0.geometry} material={materials.base_trim} position={[3960.451, 253.725, 264.115]} />
        <mesh geometry={nodes.Example_Panels_007001_decals_0.geometry} material={materials.decals} position={[4803.826, -6.389, 242.433]} scale={[5.422, 1, 1]} />
        <mesh geometry={nodes.Example_Panels_007021_decals_0.geometry} material={materials.decals} position={[4803.826, -6.389, 242.433]} scale={[5.422, 1, 1]} />
        <mesh geometry={nodes.Example_Panels_007022_decals_0.geometry} material={materials.decals} position={[4803.826, -6.389, 246.613]} scale={[5.422, 1, 1]} />
        <mesh geometry={nodes.Example_Panels_007023_decals_0.geometry} material={materials.decals} position={[4803.826, -6.389, 94.226]} scale={[5.422, 1, 1]} />
        <mesh geometry={nodes.Example_Sheet_001003_decals_0.geometry} material={materials.decals} position={[4898.386, -6.494, 579.816]} rotation={[Math.PI, 0, Math.PI]} />
        <mesh geometry={nodes.Example_Sheet_001005_decals_0.geometry} material={materials.decals} position={[4944.563, -6.473, 251.19]} rotation={[Math.PI, 0, Math.PI]} />
        <mesh geometry={nodes.Example_Sheet_001006_decals_0.geometry} material={materials.decals} position={[4897.513, -6.598, 404.213]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes.Example_Sheet_001007_decals_0.geometry} material={materials.decals} position={[4932.957, -6.494, 110.097]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes.Example_Sheet_001010_decals_0.geometry} material={materials.decals} position={[4081.088, -19.856, 665.661]} rotation={[Math.PI, 0, Math.PI]} />
        <mesh geometry={nodes.Example_Sheet_001078_decals_0.geometry} material={materials.decals} position={[4286.94, 45.864, 363.44]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} />
        <mesh geometry={nodes.Example_Sheet_001082_decals_0.geometry} material={materials.decals} position={[3683.032, -19.972, 795.566]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes.Example_Sheet_008014_decals_0.geometry} material={materials.decals} position={[4419.187, 589.498, 575.663]} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh geometry={nodes.Example_Sheet_008029_decals_0.geometry} material={materials.decals} position={[4549.414, 539.203, 198.287]} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh geometry={nodes.Example_Sheet_008033_decals_0.geometry} material={materials.decals} position={[4644.677, 539.203, -179.359]} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh geometry={nodes.Example_Sheet_036002_decals_0.geometry} material={materials.decals} position={[4411.102, -19.937, 478.472]} rotation={[0, -1.571, 0]} />
        <mesh geometry={nodes.Example_Sheet_036030_decals_0.geometry} material={materials.decals} position={[4671.936, 549.571, 333.925]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        <mesh geometry={nodes.Example_Sheet_036031_decals_0.geometry} material={materials.decals} position={[4762.037, 550, 515.629]} rotation={[0, -Math.PI / 2, 0]} scale={2.206} />
        <mesh geometry={nodes.Example_Sheet_042002_decals_0.geometry} material={materials.decals} position={[5086.934, -6.095, 200.204]} />
        <mesh geometry={nodes.Example_Sheet_042004_decals_0.geometry} material={materials.decals} position={[5063.857, -5.515, 489.766]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes.Example_Sheet_045003_decals_0.geometry} material={materials.decals} position={[3816.268, -19.96, 769.362]} rotation={[0, -Math.PI / 2, 0]} />
        <mesh geometry={nodes.Example_Sheet_045005_decals_0.geometry} material={materials.decals} position={[3816.268, -19.922, 352.003]} rotation={[0, -Math.PI / 2, 0]} />
        <mesh geometry={nodes.Example_Sheet_047_decals_0.geometry} material={materials.decals} position={[4833.26, 23.489, 285.252]} rotation={[0, -Math.PI / 2, 0]} scale={0.599} />
        <mesh geometry={nodes.Example_Sheet_047001_decals_0.geometry} material={materials.decals} position={[4831.225, 23.521, 363.272]} rotation={[0, -Math.PI / 2, 0]} scale={0.599} />
        <mesh geometry={nodes.Example_Sheet_047012_decals_0.geometry} material={materials.decals} position={[3681.468, 10.166, 581.323]} rotation={[0, -Math.PI / 2, 0]} scale={0.599} />
        <mesh geometry={nodes.Example_Sheet_047013_decals_0.geometry} material={materials.decals} position={[3684.152, 10.166, 581.39]} rotation={[0, -Math.PI / 2, 0]} scale={0.599} />
        <mesh geometry={nodes.Example_Sheet_047025_decals_0.geometry} material={materials.decals} position={[3684.152, 10.166, 581.39]} rotation={[0, -Math.PI / 2, 0]} scale={0.599} />
        <mesh geometry={nodes.Example_Sheet_047026_decals_0.geometry} material={materials.decals} position={[3684.152, 10.166, 581.39]} rotation={[0, -Math.PI / 2, 0]} scale={0.599} />
        <mesh geometry={nodes.Example_Sheet_047027_decals_0.geometry} material={materials.decals} position={[4787.245, 570.96, 428.385]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
        <mesh geometry={nodes.Example_Sheet_047028_decals_0.geometry} material={materials.decals} position={[4807.452, 582.571, 367.898]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[-0.599, 0.599, 0.599]} />
        <mesh geometry={nodes.Examples_005_decals_0.geometry} material={materials.decals} position={[3613.243, 10, 580.227]} rotation={[0, -Math.PI / 2, 0]} />
        <mesh geometry={nodes.Examples_005001_decals_0.geometry} material={materials.decals} position={[4848.725, 23.652, 325.987]} rotation={[0, -Math.PI / 2, 0]} />
        <mesh geometry={nodes.Examples_005017_decals_0.geometry} material={materials.decals} position={[4848.725, 23.652, 325.987]} rotation={[0, -Math.PI / 2, 0]} />
        <mesh geometry={nodes.Examples_005019_decals_0.geometry} material={materials.decals} position={[4959.103, 10.339, -182.605]} rotation={[0, -Math.PI / 2, 0]} />
        <mesh geometry={nodes['INSTANT_133_abbc0c72-24fb-49ce-97ef-7cabb129e883_decals_0'].geometry} material={materials.decals} position={[4960.4, 0, 0]} />
        <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation018_decals_0.geometry} material={materials.decals} position={[4107.864, 303.337, 860.766]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[-4.273, 4.273, 4.273]} />
        <mesh geometry={nodes.MyDecals_019_wire_cloth_insulation030_decals_0.geometry} material={materials.decals} position={[4107.864, 303.337, 860.766]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[-4.273, 4.273, 4.273]} />
        <mesh geometry={nodes.MyDecals_021_LONG_Light004_decals_0.geometry} material={materials.decals} position={[4081.633, 563.664, 550.682]} rotation={[-2.238, Math.PI / 2, 0]} scale={[-0.7, 1, 21.517]} />
        <mesh geometry={nodes.MyDecals_021_LONG_Light007_decals_0.geometry} material={materials.decals} position={[4081.633, 563.664, 550.682]} rotation={[-2.238, Math.PI / 2, 0]} scale={[-0.7, 1, 21.517]} />
        <mesh geometry={nodes.NurbsPath019_Material007_0.geometry} material={materials['Material.007']} position={[4652.728, 490.231, -76.635]} rotation={[0, Math.PI / 2, 0]} scale={[0.496, 1, 1]} />
        <mesh geometry={nodes.NurbsPath066_Material007_0.geometry} material={materials['Material.007']} position={[5274.546, 488.11, -176.392]} rotation={[0, -Math.PI / 2, 0]} scale={[-0.496, 1, 1]} />
        <mesh geometry={nodes.Plane018_base_trim_0.geometry} material={materials.base_trim} position={[4931.871, 580, 550.24]} />
        <mesh geometry={nodes.Plane042_base_trim_0.geometry} material={materials.base_trim} position={[4610.645, -10.909, 229.013]} />
        <mesh geometry={nodes.Plane045_base_trim_0.geometry} material={materials.base_trim} position={[4795.83, -6.667, 414.199]} />
        <mesh geometry={nodes.Plane046_base_trim_0.geometry} material={materials.base_trim} position={[4977.743, -10.909, 557.161]} />
        <mesh geometry={nodes.Plane049_base_trim_0.geometry} material={materials.base_trim} position={[4081.631, -10, 285.227]} />
        <mesh geometry={nodes.Plane051_base_trim_0.geometry} material={materials.base_trim} position={[4081.631, -5, 580.227]} />
        <mesh geometry={nodes.Plane052_base_trim_0.geometry} material={materials.base_trim} position={[4081.631, -10, 836.137]} />
        <mesh geometry={nodes.Plane055_base_trim_0.geometry} material={materials.base_trim} position={[3681.631, -10, 285.227]} />
        <mesh geometry={nodes.Plane058_base_trim_0.geometry} material={materials.base_trim} position={[3681.631, -10, 836.137]} />
        <mesh geometry={nodes.Plane061_base_trim_0.geometry} material={materials.base_trim} position={[4666.859, -10, -180]} />
        <mesh geometry={nodes.Plane064_base_trim_0.geometry} material={materials.base_trim} position={[5256.859, -10, -238.951]} />
        <mesh geometry={nodes.Plane076_base_trim_0.geometry} material={materials.base_trim} position={[4633.376, 580, 237.495]} />
        <mesh geometry={nodes.Plane077_base_trim_0.geometry} material={materials.base_trim} position={[4659.126, -30, 277.495]} />
        <mesh geometry={nodes.Plane078_base_trim_0.geometry} material={materials.base_trim} position={[4790.931, 646.956, 409.3]} />
        <mesh geometry={nodes.Plane079_base_trim_0.geometry} material={materials.base_trim} position={[4775.215, -29.928, 394.237]} />
        <mesh geometry={nodes.Plane080_base_trim_0.geometry} material={materials.base_trim} position={[4931.871, -30, 550.24]} />
        <mesh geometry={nodes.Plane082_base_trim_0.geometry} material={materials.base_trim} position={[4081.631, 580, 320.227]} />
        <mesh geometry={nodes.Plane084_base_trim_0.geometry} material={materials.base_trim} position={[4061.631, 650, 580.227]} />
        <mesh geometry={nodes.Plane087_base_trim_0.geometry} material={materials.base_trim} position={[3681.209, 580, 800.227]} />
        <mesh geometry={nodes.Plane088_base_trim_0.geometry} material={materials.base_trim} position={[3681.631, 580, 320.227]} />
        <mesh geometry={nodes.Plane090_base_trim_0.geometry} material={materials.base_trim} position={[3681.631, 650, 580.227]} />
        <mesh geometry={nodes.Plane093_base_trim_0.geometry} material={materials.base_trim} position={[5181.859, 580, -180.018]} />
        <mesh geometry={nodes.Plane094_base_trim_0.geometry} material={materials.base_trim} position={[4701.859, 580, -180]} />
        <mesh geometry={nodes.Plane096_base_trim_0.geometry} material={materials.base_trim} position={[4961.859, 650, -230]} />
        <mesh geometry={nodes.Plane097_base_trim_0.geometry} material={materials.base_trim} position={[4942.313, -30, -180]} />
        <mesh geometry={nodes.Plane098_base_trim_0.geometry} material={materials.base_trim} position={[5142.768, -30, -180]} />
        <mesh geometry={nodes.Plane109_Material007_0.geometry} material={materials['Material.007']} position={[5280.376, 41.874, -238.877]} />
        <mesh geometry={nodes.Plane265_Material007_0.geometry} material={materials['Material.007']} position={[5280.724, 42.005, -41.652]} rotation={[0, Math.PI / 2, 0]} scale={1.087} />
        <mesh geometry={nodes.Plane280_Material007_0.geometry} material={materials['Material.007']} position={[4966.318, 169.891, 561.529]} />
        <mesh geometry={nodes.Plane294_Material007_0.geometry} material={materials['Material.007']} position={[448.499, -9.748, -168.877]} />
        <mesh geometry={nodes.Santus_scifi_274_Decal_274009_decals_0.geometry} material={materials.decals} position={[3885.042, 423.087, 260]} rotation={[Math.PI / 2, -0.005, 0]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes.TrimSheet_002021_decals_0.geometry} material={materials.decals} position={[5115.423, 428.212, 597.504]} rotation={[Math.PI / 2, 0, 0]} scale={1.881} />
        <mesh geometry={nodes.TrimSheet_002023_decals_0.geometry} material={materials.decals} position={[4224.221, 376.212, 240]} rotation={[Math.PI / 2, 0, 0]} scale={1.881} />
      </group>
    </group>
  )
}

useGLTF.preload('/model.glb')

import React, { useEffect, useState } from "react";import LazyImage from "../LazyImage/LazyImage";
import Navigation from "../Navigation/Navigates";
import * as motion from "motion/react-client";
import { useInView } from "react-intersection-observer";
import team from "../Assets/teamx.png";
import journey from "../Assets/journey.png";
import ourjourney from "../Assets/ourjourney.png";
import expertise from "../Assets/expertise.png";
import inception from "../Assets/inception.png";
import standards from "../Assets/standards.png";
import technology from "../Assets/technology.png";
import imgtwo from "../Assets/imgzero.jpg";
import imgone from "../Assets/imgtwo.jpg";
import intro from "../Assets/aboutvid.mp4";
import inno from "../Assets/usabout.png";
import audio from "../Assets/audio.png";
import mute from "../Assets/mute.png";
import facebook from "../Assets/facebook.png";
import twitter from "../Assets/twitter.png";
import insta from "../Assets/insta.png";
import what from "../Assets/doss.png";
import fifteen from "../Assets/fifteen.mp4";
import seven from "../Assets/seven.mp4";
import twenty from "../Assets/twenty.mp4";
import twefour from "../Assets/twefour.mp4";
import animvid from "../Assets/upperlines.mp4";
import MousePointer from "../Mousepointer/Mousepointer";
import marketingvid from "../Assets/marketing.mp4";
import impacts from "../Assets/impactx.png";
export default function About() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Regex pattern for checking common mobile user agents
      const isMobileDevice =
        /android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ipad|ipod|iris|kindle|midp|mmp|mobile|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|silk|series(4|6)0|symbian|up\.browser|up\.link|vodafone|wap|windows ce|xda|xiino/i.test(
          userAgent
        );
      setIsMobile(isMobileDevice);
    };

    checkIfMobile();
  }, []);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust as needed
  });
  const [audioinput, setAudio] = useState(false);

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (inView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [inView, hasAnimated]);

  const audioSwitch = () => {
    setAudio(!audioinput);
  };

  return (
    <div className="aboutpage">
      <div className="allpages">
        <Navigation />
        <div className="aboutcontents">
          <div className="aboutus">
            <img className="scroll-image" src={inno} alt="#" loading="lazy"/>
            <img className="scroll-image" src={what} alt="#" loading="lazy"/>
            {/* <img className="scroll-image" src={offer} alt="#" /> */}
          </div>

          <div className="lower">
            <div className="aboutleft">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 15,
                  scale: { type: "spring", visualDuration: 1, bounce: 0.5 },
                }}
                className="first"
              >
                <img className="impacts" src={impacts} alt="#" loading="lazy"/>
              </motion.div>
              <div className="aboutdesc">
                <div className="borderabout"></div>
                <div className="crcs"></div>
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 1,
                    scale: {
                      type: "spring",
                      visualDuration: 0.01,
                      bounce: 0.2,
                    },
                  }}
                  className="aboutdata"
                >
                  <p>
                    At Imperial Marketing and Communication, we help brands grow
                    through strategic branding, creative storytelling, and
                    data-driven marketing. From compelling campaigns to digital
                    engagement, we transform ideas into success stories,
                    ensuring your brand not only stands out but thrives.
                  </p>
                </motion.div>
              </div>
            </div>

            <div className="aboutright">
              <div className="imageone">
                <img src={imgone} alt="#" loading="lazy"/>
              </div>
              <div className="imagetwo">
                <img src={imgtwo} alt="#" loading="lazy"/>
              </div>
              <div className="imagethree">
              <img src={team} alt="#" loading="lazy"/>
              <p>Our Team</p>
              </div>
            </div>
          </div>
          <MousePointer />
         
          <div className="smartcenter">
            <div className="tour">
              <video
                autoPlay
                muted={audioinput ? false : true} // Corrected conditional muting
                loop
                
                playsInline
                className="centervideo"
              >
                <source src={intro} type="video/mp4" />
              </video>
              {/* <div className="crcs" style={{
                position: "absolute",
                top: "99%",
                right: "5%",
                zIndex: "-1",
                transform: "translate(-50%, -50%)",
              }}></div> */}
              <div className="audiocontrol" onClick={audioSwitch}>
                {!audioinput && <img src={audio} alt="" loading="lazy"/>}
                {audioinput && <img src={mute} alt="" loading="lazy"/>}
              </div>
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 15,
                  scale: { type: "spring", visualDuration: 1, bounce: 0.5 },
                }}
                className="smartxcenter"
              >
                <p>Imperial Marketing</p>
                <p>Feel The Power</p>
              </motion.div>
            </div>
          </div>

          {!isMobile && (
            <div className="years">
              <div className="yrsinto">
                <img src={journey} alt="#" loading="lazy"/>
              </div>

              <div className="yrsintox">
                <img src={ourjourney} alt="#" loading="lazy"/>
              </div>

              <div className="animvid">
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  
                  // onEnded={() => setEnded(true)}
                  className="numbervidy"
                >
                  <source src={animvid} type="video/mp4" />
                </video>
              </div>

              <div className="yearone">
                <div className="yrno">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    
                    // onEnded={() => setEnded(true)}
                    className="numbervid"
                  >
                    <source src={fifteen} type="video/mp4" />
                  </video>
                </div>
                <div className="yearintersect">
                  <div className="square"></div>
                  <div className="pup">
                    <div className="ccc"></div>
                  </div>
                </div>
                <div className="yrevent">
                  <img id="rrvnt" src={inception} alt="#" loading="lazy"/>
                  <span>
                    In 2015 we ambarked on a remarkable journey with humble
                    beginning, primarily offering transit media services. Back
                    then, our focus was on branding public transportation and
                    introducting screens inside buses, making a significant
                    impression in the marketing landscape.
                  </span>
                </div>
              </div>
             
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 1,
                  scale: {
                    type: "spring",
                    visualDuration: 0.01,
                    bounce: 0.2,
                  },
                }}
                className="yeartwo"
              >
                <div className="yrnosx">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    // onEnded={() => setEnded(true)}
                    className="numbervid"
                  >
                    <source src={seven} type="video/mp4" />
                  </video>
                </div>
                <div className="yrevents">
                  <img id="aauea" src={expertise} alt="#" loading="lazy"/>
                  <span id="ayear">
                    A year fueled by a relentless pursuit of excellence, we
                    expanded into experiential marketing. This pivotal moment
                    marked the inception of our roadshows, activations,
                    point-of-sale (POS) services, and exceptional branding and
                    setups. We were on a mission to create unforgettable brand
                    experiences.
                  </span>
                </div>

                <div className="yearintersect">
                  <div className="square"></div>
                  <div className="pup">
                    <div className="ccc"></div>
                  </div>
                </div>

                <div className="yrnos">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    
                    // onEnded={() => setEnded(true)}
                    className="numbervid"
                  >
                    <source src={seven} type="video/mp4" />
                  </video>
                </div>
              </motion.div>

              <div className="yeartwo">
                <div className="yrno">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    
                    playsInline
                    // onEnded={() => setEnded(true)}
                    className="numbervid"
                  >
                    <source src={twenty} type="video/mp4" />
                  </video>
                </div>
                <div className="yearintersect">
                  <div className="square"></div>
                  <div className="pup">
                    <div className="ccc"></div>
                  </div>
                </div>
                <div className="yrevent">
                  <img id="rrvnts" src={technology} alt="#" loading="lazy"/>
                  <span>
                    A turning point year as we evolved into a full-fledged
                    experiential agency. Recognizing the potential of
                    technology, we ventured into the realm of innovation,
                    dedicating ourselves to research and development while
                    conceptualizing immersive and interactive experiences. This
                    marked the birth of a hybrid agency, where creativity and
                    technology converged to shape the future of marketing.
                  </span>
                </div>
              </div>
            
              <motion.div
                ref={ref}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 1,
                  scale: {
                    type: "spring",
                    visualDuration: 0.31,
                    bounce: 0.3,
                  },
                }}
                className="yeartwo"
              >
                <div className="yrevents">
                  <div className="yrnosx">
                    <video
                      autoPlay
                      muted
                      loop={false}
                      playsInline
                      
                      // onEnded={() => setEnded(true)}
                      className="numbervid"
                    >
                      <source src={twefour} type="video/mp4" />
                    </video>
                  </div>
                 <LazyImage src={standards} alt="#" />
                  <span>
                    Imperial Marketing and Communication Limited had become
                    synonymous with creative and innovative excellence in
                    Tanzania. Our immersive and interactive engagements in
                    experiential marketing had a profound impact on the
                    Tanzanian marketing ecosystem, redefining the rules of
                    engagement and leaving lasting impressions on brands and
                    consumers alike. We were honored to receive an award for our
                    innovative approach to experiential marketing.
                  </span>
                </div>
                {/* <div className="crc"></div> */}
                <div className="yearintersect">
                  <div className="square"></div>
                  <div className="pup">
                    <div className="ccc"></div>
                  </div>
                </div>

                <div className="yrnos">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    
                    // onEnded={() => setEnded(true)}
                    className="numbervid"
                  >
                    <source src={twefour} type="video/mp4" />
                  </video>
                </div>
              </motion.div>

              <div className="lowersect">
                <div className="imc">
                  <p>imc@2025</p>
                </div>

                <div className="linetrr"></div>

                <div className="socials">
                  <div className="socs">
                   <LazyImage src={facebook} alt="#" />
                  </div>
                  <div className="socs">
                    {" "}
                   <LazyImage src={insta} alt="#" />
                  </div>
                  <div className="socs">
                    {" "}
                   <LazyImage src={twitter} alt="#" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {isMobile && (
            <div className="ismobile">
              <div className="mobileintro">
               <LazyImage src={ourjourney} alt="#" />
              </div>
              <div className="mobileone">
                <div className="mobileword">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    // onEnded={() => setEnded(true)}
                    className="mobilenumb"
                  >
                    <source src={fifteen} type="video/mp4" />
                  </video>
                </div>
                <div className="mobiledesc">
                <div className="crcsx"></div>
                 <LazyImage id="mobileimg" src={inception} alt="#" />
                  <span>
                    In 2015 we ambarked on a remarkable journey with humble
                    beginning, primarily offering transit media services. Back
                    then, our focus was on branding public transportation and
                    introducting screens inside buses, making a significant
                    impression in the marketing landscape.
                  </span>
                </div>
              </div>
              <div className="mobileone">

                <div className="mobileword">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    // onEnded={() => setEnded(true)}
                    className="mobilenumb"
                  >
                    <source src={seven} type="video/mp4" />
                  </video>
                </div>
                <div className="mobiledesc">
                <div className="crcs"></div>
                 <LazyImage id="mobileimg" src={expertise} alt="#" />
                  <span>
                    A year fueled by a relentless pursuit of excellence, we
                    expanded into experiential marketing. This pivotal moment
                    marked the inception of our roadshows, activations,
                    point-of-sale (POS) services, and exceptional branding and
                    setups. We were on a mission to create unforgettable brand
                    experiences.
                  </span>
                </div>
              </div>
              <div className="mobileone">
                <div className="mobileword">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    // onEnded={() => setEnded(true)}
                    className="mobilenumb"
                  >
                    <source src={twenty} type="video/mp4" />
                  </video>
                </div>
                <div className="mobiledesc">
                <div className="crcsx"></div>
                 <LazyImage id="mobileimg" src={technology} alt="#" />
                  <span>
                    A turning point year as we evolved into a full-fledged
                    experiential agency. Recognizing the potential of
                    technology, we ventured into the realm of innovation,
                    dedicating ourselves to research and development while
                    conceptualizing immersive and interactive experiences.
                  </span>
                </div>
              </div>
              <div className="mobileone">
                <div className="mobileword">
                  <video
                    autoPlay
                    muted
                    loop={false}
                    playsInline
                    // onEnded={() => setEnded(true)}
                    className="mobilenumb"
                  >
                    <source src={twefour} type="video/mp4" />
                  </video>
                </div>
                <div className="mobiledesc">
                <div className="crcs"></div>
                 <LazyImage id="mobileimg" src={standards} alt="#" />
                  <span>
                    Imperial Marketing and Communication Limited had become
                    synonymous with creative and innovative excellence in
                    Tanzania. Our immersive and interactive engagements in
                    experiential marketing had a profound impact on the
                    Tanzanian marketing ecosystem.
                  </span>
                </div>
              </div>

              <div className="lowersect">
                <div className="imc">
                  <p>imc@2025</p>
                </div>

                <div className="linetrr"></div>

                <div className="socials">
                  <div className="socs">
                   <LazyImage src={facebook} alt="#" />
                  </div>
                  <div className="socs">
                    {" "}
                   <LazyImage src={insta} alt="#" />
                  </div>
                  <div className="socs">
                    {" "}
                   <LazyImage src={twitter} alt="#" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

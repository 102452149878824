import React, { useState, useEffect } from 'react';

const Mousepointers = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
     const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

     window.addEventListener('mousemove', handleMouseMove);

     return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="mousepointers" >
        <div className='midcircles'>
        </div>
    </div>
  );
};


export default Mousepointers;

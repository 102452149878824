import React, { useState, useEffect } from 'react';

const Mousepointer = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
     const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

     window.addEventListener('mousemove', handleMouseMove);

     return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="mousepointer" style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}>
        <div className='midcircle'>
        </div>
    </div>
  );
};


export default Mousepointer;

import React, { useEffect, useState } from "react";import LazyImage from "../LazyImage/LazyImage";
import { NavLink } from "react-router-dom";
import Navigation from "../Navigation/Navigates";
import * as motion from "motion/react-client";
import { useInView } from "react-intersection-observer";
import conceptx from "../Assets/concs.png";
import concepts from "../Assets/hymark.png";
import conceptxs from "../Assets/virtualreality.png";
import MousePointer from "../Mousepointer/Mousepointer";
import conceptxse from "../Assets/augmrealitys.png";
import conceptxsei from "../Assets/games.png";
import holo from "../Assets/holo.png";
import holox from "../Assets/runimage.jpeg";
import arx from "../Assets/arx.jpeg";
import arsx from "../Assets/inter.jpeg";
import arsxa from "../Assets/puzzle.jpeg";
import vrs from "../Assets/vrhead.png";
import cycles from "../Assets/cycles.jpeg";
import cyclx from "../Assets/bby.png";
import vrsx from "../Assets/arvideos.png";
import vrsxx from "../Assets/launchg.png";
import runs from "../Assets/ruun.png";
import vrsxxx from "../Assets/wid.png";
import inno from "../Assets/brand.png";
import what from "../Assets/pos.png";
import finger from "../Assets/touchidd.png";
import feel from "../Assets/powerss.png";
import puzz from "../Assets/puz.png";
import marketingfive from "../Assets/marketingfive.mp4";
import iwdisplay from "../Assets/walls.mp4";
import bycle from "../Assets/cycli.mp4";
import hos from "../Assets/ho.mp4";
import runx from "../Assets/run.mp4";
import puzzle from "../Assets/puzzle.mp4";
export default function Branding() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust as needed
  });
  const [audioinput, setAudio] = useState(false);
  const [switchplay, setSwitchplay] = useState(true);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [switchone, setSwitchone] = useState(true);
  const [swichtwo, setSwitchtwo] = useState(true);
  const [switchthree, setSwitchthree] = useState(true);
  const [switchfour, setSwitchfour] = useState(true);
  const [switchfive, setSwitchfive] = useState(true);

  useEffect(() => {
    if (inView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [inView, hasAnimated]);

  const audioSwitch = () => {
    setAudio(!audioinput);
  };

  const switchPlays = () => {
    setSwitchplay(!switchplay);
  };

  const switchOne = () => {
    setSwitchone(!switchone);
  };

  const switchTwo = () => {
    setSwitchtwo(!swichtwo);
  };

  const switchThree = () => {
    setSwitchthree(!switchthree);
  };

  const switchFour = () => {
    setSwitchfour(!switchfour);
  };

  const switchFive = () => {
    setSwitchfive(!switchfive);
  };

  return (
    <div className="aboutpage">
      <div className="allpages">
        <Navigation />
        <div className="aboutcontents">
          <div className="aboutuso">
           <LazyImage className="scroll-image" src={inno} alt="#" />
           <LazyImage className="scroll-image" src={what} alt="#" />
            {/*<LazyImage className="scroll-image" src={offer} alt="#" /> */}
          </div>
          <MousePointer />
          <div className="upperx">
            <NavLink to="/hybrid-marketing" className="sects">
              <p className="planning">Hybrid Marketing</p>
            </NavLink>

            <NavLink to="/digitized-launch" className="sects">
              <p>Digitized Launch</p>
            </NavLink>

            <NavLink to="/roadshows" className="sects">
              <p>Roadshow & Activations</p>
            </NavLink>

            <NavLink to="/influencer" className="sects">
              <p>Influencer Management</p>
            </NavLink>

            <NavLink to="/trade-shows" className="sects">
              <p>Others</p>
            </NavLink>

            {/* <NavLink to="/trade-shows" className="sects">
              <p>Press Conference</p>
            </NavLink>
            <NavLink to="/trade-shows" className="sects">
              <p>Exhibitions & Tradeshows</p>
            </NavLink> */}
          </div>

          <div className="middsx">
            <div className="concpt">
             <LazyImage src={concepts} alt="" />
            </div>

            <p className="concword">
              Elevate your brand's visibility with our branding and point of
              sale (POS) placement solutions. We strategically position your
              brand where it matters most, ensuring it stands out in the crowded
              marketplace.
            </p>

            <div className="concpt">
             <LazyImage src={conceptx} alt="" />
            </div>
          </div>

          <div className="lowersectx">
            <div className="concptei">
             <LazyImage src={conceptxs} alt="" />
            </div>

            {/* <div className="midbox"></div> */}

            <div className="concptdets">
              <div className="picsbox">
               <LazyImage src={vrs} alt="" />
                <div className="vrra">
                 <LazyImage src={feel} alt="" />
                </div>
              </div>

              {/* <div className="picsbox">
                {" "}
                <div className="picsbox">
                 <LazyImage src={vrs} alt="" />
                </div>
              </div> */}
            </div>
          </div>

          <div className="lowersectx">
            <div className="concpteii">
             <LazyImage src={conceptxse} alt="" />
            </div>

            <div className="augpics">
              <div className="picone">
                {switchone &&<LazyImage src={arx} alt="" />}
                <div className="cirdiv" onClick={switchOne}>
                 <LazyImage src={finger} alt="" />
                </div>

                {!switchone && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    playsInline
                    onEnded={() => setSwitchone(true)}
                    className="centervideoxss"
                  >
                    <source src={marketingfive} type="video/mp4" />
                  </video>
                )}

                <div className="vrr">
                 <LazyImage src={vrsx} alt="" />
                </div>
              </div>

              <div className="picone">
                {swichtwo &&<LazyImage src={holo} alt="" />}
                <div className="cirdiv" onClick={switchTwo}>
                 <LazyImage src={finger} alt="" />
                </div>

                {!swichtwo && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    playsInline
                    onEnded={() => setSwitchtwo(true)}
                    className="centervideoxss"
                  >
                    <source src={hos} type="video/mp4" />
                  </video>
                )}

                <div className="vrra">
                 <LazyImage src={vrsxx} alt="" />
                </div>
              </div>

              <div className="piconex">
                {switchplay &&<LazyImage src={arsx} alt="" />}
                <div className="cirdiv" onClick={switchPlays}>
                 <LazyImage src={finger} alt="" />
                </div>

                {!switchplay && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    playsInline
                    onEnded={() => setSwitchplay(true)}
                    className="centervideoxss"
                  >
                    <source src={iwdisplay} type="video/mp4" />
                  </video>
                )}
                <div className="vrra">
                 <LazyImage src={vrsxxx} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="dotsted"></div>
          <div className="dotsted"></div>

          <div className="lowersectx">
            <div className="concpteu">
             <LazyImage src={conceptxsei} alt="" />
            </div>

            <div className="augpics">
              <div className="picone">
                {switchthree &&<LazyImage src={cycles} alt="" />}
                <div className="cirdiv" onClick={switchThree}>
                 <LazyImage src={finger} alt="" />
                </div>

                {!switchthree && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    playsInline
                    onEnded={() => setSwitchthree(true)}
                    className="centervideoxss"
                  >
                    <source src={bycle} type="video/mp4" />
                  </video>
                )}

                <div className="vrr">
                 <LazyImage src={cyclx} alt="" />
                </div>
              </div>

              <div className="picone">
                {switchfour &&<LazyImage src={holox} alt="" />}
                <div className="cirdiv" onClick={switchFour}>
                 <LazyImage src={finger} alt="" />
                </div>

                {!switchfour && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    playsInline
                    onEnded={() => setSwitchtwo(true)}
                    className="centervideoxss"
                  >
                    <source src={runx} type="video/mp4" />
                  </video>
                )}

                <div className="vrra">
                 <LazyImage src={runs} alt="" />
                </div>
              </div>

              <div className="piconex">
                {switchfive &&<LazyImage src={arsxa} alt="" />}
                <div className="cirdiv" onClick={switchFive}>
                 <LazyImage src={finger} alt="" />
                </div>

                {!switchfive && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    playsInline
                    onEnded={() => setSwitchfive(true)}
                    className="centervideoxss"
                  >
                    <source src={puzzle} type="video/mp4" />
                  </video>
                )}
                <div className="vrra">
                 <LazyImage src={puzz} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Suspense, useEffect, useRef, useState } from "react"; 
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import Model from "./Model.jsx";
import Navigation from "../Navigation/Navigates";
import * as motion from "motion/react-client";
import intro from "../Assets/themasai.mp4";
import explore from "../Assets/explorehub.png";
import corporate from "../Assets/corporatevent.png";
import business from "../Assets/forbusiness.png";
import MousePointer from "../Mousepointer/Mousepointer";
import soundFile from "../Assets/beep.wav";
import soundFile2 from "../Assets/chip.wav";
import arrows from "../Assets/ars.png";
import arrowb from "../Assets/backarrow.png";
import collection from "../Assets/collection.png";
import moran from "../Assets/moran.png";
import table from "../Assets/table.png";
import smart from "../Assets/creatives.png";
import ourx from "../Assets/ourworkshop.png";
import tablet from "../Assets/fabricate.png";
import locker from "../Assets/wookd.png";
import webcam from "../Assets/metal.png";
import spray from "../Assets/sprays.png";
import touch from "../Assets/touch.png";
import exploremobile from "../Assets/explorecenter.png";

import interactive from "../Assets/interacts.png";
import flat from "../Assets/flatpanel.png";
import photobooth from "../Assets/prints.png";
import programming from "../Assets/softwares.png";
import our from "../Assets/ourworks.png";
import smartx from "../Assets/smart.png";
import devices from "../Assets/devices.png";
import hubx from "../Assets/hubs.png";
import kiosks from "../Assets/kioskx.png";
import preview from "../Assets/previews.png";

import oursmart from "../Assets/smartwhite.png";

const Scene = () => {
  return (
    <>
      <Suspense fallback={null}>
        <Model />
      </Suspense>
      {/* Replace this light as needed; it's a good start */}
      <ambientLight intensity={0.5} />
    </>
  );
};

export default function Fabrication() {
  const [ourdevices, setOurdevices] = useState(true);
  const [devicenavs, setDevicenavs] = useState(true);
  const [allpointers, setAllpointers] = useState(false);
  const [nextprev, setNextprev] = useState(false);
  const [tablex, setTable] = useState(false);
  const [explorestatus, setExplorestatus] = useState(false);
  const [tabletx, setTabletx] = useState(false);
  const [lockerx, setLockerx] = useState(false);
  const [webcamx, setWebcamx] = useState(false);
  const [experientialx, setExperientialx] = useState(false);
  const audioRef = useRef(new Audio(soundFile));
  const audioRefs = useRef(new Audio(soundFile2));
  const [menus, setMenu] = useState(false);
  const [smartsx, setSmartsx] = useState(false);
  const [dualscreen, setDualscreen] = useState(false);
  const [portrait, setPortrait] = useState(false);
  const [flatpanel, setFlatpanel] = useState(false);
  const [booth, setBooth] = useState(false);
  const [kiosktu, setKiosktu] = useState(false);
  var [flag, setFlag] = useState(true);
  var [countup, setCountup] = useState(1);
  var [currentcount, setCurrentcount] = useState(0);
  var [modelshift, setModelshift] = useState(38);
  const [rotates, setRotates] = useState(false);
  const switchOffall = () => {
    playSound();
    flag = !devicenavs;

    if (flag === false) {
      setOurdevices(false);
      setDevicenavs(false);
      setAllpointers(false);
      setNextprev(false);
      setExplorestatus(false);
      setTable(false);
      setTabletx(false);
      setLockerx(false);
      setWebcamx(false);
      setExperientialx(false);
      setSmartsx(false);
      setDualscreen(false);
      setPortrait(false);
      setFlatpanel(false);
      setBooth(false);
      setKiosktu(false);
      setRotates(true);
    } else {
      offAll();
      setOurdevices(true);
      setDevicenavs(true);
      setRotates(false);
      setCountup(1);
    }
  };

  const offAll = () => {
    setOurdevices(false);
    setDevicenavs(false);
    setAllpointers(false);
    setNextprev(false);
    setExplorestatus(false);
    setTable(false);
    setTabletx(false);
    setLockerx(false);
    setWebcamx(false);
    setExperientialx(false);
    setSmartsx(false);
    setDualscreen(false);
    setPortrait(false);
    setFlatpanel(false);
    setBooth(false);
    setKiosktu(false);
  };

  const exploreHub = () => {
    playSound();
    offAll();
    setAllpointers(true);
    setExplorestatus(true);
    setNextprev(true);
  };

  const playNext = () => {
    playSound();
    if (countup === 1) {
      offAll();
      setTabletx(true);
      setModelshift(40)
    } else if (countup === 2) {
      offAll();
      setTable(true);
      setModelshift(41)
    } else if (countup === 3) {
      offAll();
      setLockerx(true);
      setModelshift(42)
    } else if (countup === 4) {
      offAll();
      setWebcamx(true);
      setModelshift(44)
    } else if (countup === 5) {
      offAll();
      setBooth(true);
      setModelshift(43)
    } else if (countup === 6) {
      offAll();
      setKiosktu(true);
      setModelshift(45)
    } else if (countup === 7) {
      offAll();
      setDualscreen(true);
      setModelshift(43)
    }
    setNextprev(true);
    setExplorestatus(true);
    setCountup(countup + 1);
    setCurrentcount(countup);
    if (countup === 8) {
      offAll();
      setOurdevices(true);
      setDevicenavs(true);
      setCountup(1);
    }
  };

  const playPrev = () => {
    playSound();
    countup = currentcount - 1;
    if (countup === 1) {
      offAll();
      setTabletx(true);
      setModelshift(40)
    } else if (countup === 2) {
      offAll();
      setTable(true);
      setModelshift(41)
    } else if (countup === 3) {
      offAll();
      setLockerx(true);
      setModelshift(42)
    } else if (countup === 4) {
      offAll();
      setWebcamx(true);
      setModelshift(44)
    } else if (countup === 5) {
      offAll();
      setBooth(true);
      setModelshift(43)
    } else if (countup === 6) {
      offAll();
      setKiosktu(true);
      setModelshift(45)
    } else if (countup === 7) {
      offAll();
      setDualscreen(true);
      setModelshift(43)
    }
    setNextprev(true);
    setExplorestatus(true);
    setCurrentcount(countup);
    if (countup === 0) {
      offAll();
      setOurdevices(true);
      setDevicenavs(true);
      setCountup(1);
    }
  };

  const playSound = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  const createMenu = () => {
    audioRefs.current.currentTime = 0;
    audioRefs.current.play();
    var flag = !menus;
    setMenu(flag);
  };
  return (
    <div className="multimediapage">
      <Navigation />
      <MousePointer />
      <div className="devices">
        <Canvas>
          <OrbitControls
            key={Math.random()}
            target={[modelshift, 3, 4]}
            minDistance={1}
            maxDistance={1}
            enableZoom={true}
            enablePan={false}
            zoomSpeed={0.5} // Adjust zoom sensitivity (optional)
            mouseButtons={{
              LEFT: THREE.MOUSE.ROTATE,
              MIDDLE: THREE.MOUSE.ZOOM, // Enable zoom with the mouse wheel or middle button
              RIGHT: THREE.MOUSE.PAN,
            }}
            makeDefault
          />
          <Scene />
        </Canvas>

        {devicenavs && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubs"
            onClick={exploreHub}
          >
            <div className="comimg">
             <img loading="lazy"  className="frsimg" src={explore} alt="" />
            </div>
            <div className="comimg" onClick={playSound}>
             <img loading="lazy"  className="frsimgs" src={corporate} alt="" />
            </div>

            <div className="comimg" onClick={playSound}>
             <img loading="lazy"  className="frsimg" src={business} alt="" />
            </div>
          </motion.div>
        )}

        {ourdevices && (
          <div className="hubsnamesxio">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.9,
                scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
              }}
              className="hubsnmx"
            >
             <img loading="lazy"  className="namezeroy" src={our} alt="" />
             <img loading="lazy"  className="namezeroyxs" src={ourx} alt="" />

              {/*<img loading="lazy"  className="nameoney" src={smartx} alt="" />
             <img loading="lazy"  className="nametwoy" src={devices} alt="" />
             <img loading="lazy"  className="namethreey" src={hubx} alt="" /> */}
            </motion.div>
          </div>
        )}

        {tablex && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnamesx"
          >
            {" "}
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="onpointers"
              onClick={playSound}
            ></motion.div>
            {/*<img loading="lazy"  className="nameone" src={moran} alt="" /> */}
           <img loading="lazy"  className="nametwoe" src={smart} alt="" />
            {/*<img loading="lazy"  className="namethree" src={table} alt="" /> */}
          </motion.div>
        )}

        {tabletx && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnamesx"
          >
            {" "}
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="onpointers"
              onClick={playSound}
            ></motion.div>
            {/*<img loading="lazy"  className="nameone" src={moran} alt="" /> */}
            {/*<img loading="lazy"  className="nametwo" src={smarttablet} alt="" /> */}
           <img loading="lazy"  className="nametwoe" src={tablet} alt="" />
          </motion.div>
        )}

        {webcamx && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnamesx"
          >
            {" "}
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="onpointers"
              onClick={playSound}
            ></motion.div>
            {/*<img loading="lazy"  className="nameone" src={moran} alt="" /> */}
            {/*<img loading="lazy"  className="nametwoxa" src={smart} alt="" /> */}
           <img loading="lazy"  className="nametwoe" src={webcam} alt="" />
          </motion.div>
        )}

        {lockerx && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnamesx"
          >
            {" "}
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="onpointers"
              onClick={playSound}
            ></motion.div>
            {/*<img loading="lazy"  className="nameone" src={moran} alt="" />
           <img loading="lazy"  className="nametwox" src={smart} alt="" /> */}
           <img loading="lazy"  className="nametwo" src={locker} alt="" />
          </motion.div>
        )}

        {booth && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnamesx"
          >
            {" "}
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="onpointers"
              onClick={playSound}
            ></motion.div>
            {/*<img loading="lazy"  className="nameonei" src={moran} alt="" /> */}
           <img loading="lazy"  className="nametwoe" src={photobooth} alt="" />
            {/*<img loading="lazy"  className="namethreex" src={locker} alt="" /> */}
          </motion.div>
        )}

        {kiosktu && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnamesx"
          >
            {" "}
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="onpointers"
              onClick={playSound}
            ></motion.div>
            {/*<img loading="lazy"  className="nameone" src={moran} alt="" /> */}
           <img loading="lazy"  className="nametwoe" src={spray} alt="" />
            {/* <p className="namethreexp">Android Version</p> */}
          </motion.div>
        )}

        {dualscreen && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnamesx"
          >
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="onpointers"
              onClick={playSound}
            ></motion.div>
            {/*<img loading="lazy"  className="nameone" src={moran} alt="" /> */}
           <img loading="lazy"  className="nametwoe" src={programming} alt="" />
            {/*<img loading="lazy"  className="namethree" src={table} alt="" /> */}
            {/* <p className="namethreexp">With Dual Screens</p> */}
          </motion.div>
        )}

        {explorestatus && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.9,
              scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
            }}
            className="hubsnames"
          >
            <div className="comimgx">
             <img loading="lazy"  className="collect" src={collection} alt="" />
            </div>
          </motion.div>
        )}

        {allpointers && (
          <>
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.9,
                scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
              }}
              className="pointers"
              onClick={playSound}
            ></motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="pointers2"
              onClick={playSound}
            ></motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="pointers3"
              onClick={playSound}
            ></motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="pointers4"
              onClick={playSound}
            ></motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="pointers5"
              onClick={playSound}
            ></motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.4,
                scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
              }}
              className="pointers6"
              onClick={playSound}
            ></motion.div>
          </>
        )}
        <div className="pan" onClick={switchOffall}>
          {/* <p>3D</p> */}
         <img loading="lazy"  src={preview} alt="" />
        </div>

        {nextprev && (
          <div className="nexts" onClick={playNext}>
            <p>Next</p>
           <img loading="lazy"  src={arrows} alt="" />
          </div>
        )}
        {nextprev && (
          <div className="prevs" onClick={playPrev}>
           <img loading="lazy"  src={arrowb} alt="" />
            <p>Prev</p>
          </div>
        )}
      </div>
      <div className="dotted">{rotates &&<img loading="lazy"  src={touch} alt="" />}</div>
      {devicenavs && (
        <div className="lowerdivx">
          <div className="sectdon">
            {/*<img loading="lazy"  className="imgx" src={touch} alt="" /> */}
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.4,
              scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
            }}
            className="sectdon"
            onClick={exploreHub}
          >
            <div className="dotccs"></div>
            <div className="dotccxs"></div>
           <img loading="lazy"  className="imgx" src={touch} alt="" />
           <img loading="lazy"  className="exp" src={exploremobile} alt="" />
            {/*<img loading="lazy"  className="imgxx" src={exploremobiles} alt="" /> */}
          </motion.div>
        </div>
      )}
    </div>
  );
}

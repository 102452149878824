import React, { useState, useEffect } from "react";import LazyImage from "../LazyImage/LazyImage";
import intro from "../Assets/themasai.mp4";
import themasai from "../Assets/themasai.mp4";
import welco from "../Assets/futur.png";
import welcox from "../Assets/mora.jpg";
import inno from "../Assets/future.png";
import Navigation from "../Navigation/Navigation";
import MousePointer from "../Mousepointer/Mousepointer";
import MousePointers from "../Mousepointer/Mousepointers";
import * as motion from "motion/react-client";
import { NavLink } from "react-router-dom";

export default function Home() {
  const [ended, setEnded] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const checkIfMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Regex pattern for checking common mobile user agents
        const isMobileDevice =
          /android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ipad|ipod|iris|kindle|midp|mmp|mobile|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|silk|series(4|6)0|symbian|up\.browser|up\.link|vodafone|wap|windows ce|xda|xiino/i.test(
            userAgent
          );
        setIsMobile(isMobileDevice);
      };
  
      checkIfMobile();
    }, []);
  const handleClick = () => {
    window.location.href =
      "https://www.youtube.com/@imperialmarketingcommunica9365";
  };
  return (
    <div className="homepage">
      {!ended && (
        <video
          autoPlay
          muted
          loop={false}
          playsInline
          
          onEnded={() => setEnded(true)}
          className="background-video"
        >
          <source src={intro} type="video/mp4" />
        </video>
      )}

      {ended && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.9,
            scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
          }}
          className="background"
        >
          <img className="background-image" src={welcox} alt="#" loading="lazy"/>
          <div className="overlay"></div>
        </motion.div>
      )}
      <MousePointer />
      <div className="allpages">
        <Navigation />
        <div className="contents">
          <div className="welcomes">
            <img className="toinno" src={inno} alt="#" loading="lazy"/>

            {/* <p>More</p> */}
          </div>
          {/* <button className="learnbtn">
            <p>Learn More</p>
          </button> */}
          <NavLink to="/hybrid-marketing">
            <button className="learnbtn">
              <p>Where Stories Aren’t Just Told, They’re Lived</p>
            </button>
          </NavLink>
        </div>
      </div>
   {!isMobile &&   <div className="crcs"></div>}
      <div className="lowerdiv">
        <div className="dotted">
          <MousePointers />
        </div>
        <img className="welco" src={welco} alt="#" loading="lazy"/>
        <NavLink to="/about">
          <button className="learnbtns">
            <p>Home of Immersive Experiential Marketing</p>
          </button>
        </NavLink>
      </div>
    </div>
  );
}

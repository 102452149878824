import React, { useEffect, useState } from "react";import LazyImage from "../LazyImage/LazyImage";
import { NavLink } from "react-router-dom";
import Navigation from "../Navigation/Navigates";
import * as motion from "motion/react-client";
import { useInView } from "react-intersection-observer";
import conceptx from "../Assets/acti.png";
import concepts from "../Assets/hymark.png";
import conceptxs from "../Assets/virtualreality.png";
import MousePointer from "../Mousepointer/Mousepointer";
import conceptxse from "../Assets/rdshowsa.png";
import conceptxsei from "../Assets/games.png";
import holo from "../Assets/social.jpg";
import holox from "../Assets/runimage.jpeg";
import arx from "../Assets/scs.jpg";
import arsx from "../Assets/inter.jpeg";
import arsxa from "../Assets/puzzle.jpeg";
import vrs from "../Assets/vrhead.png";
import cycles from "../Assets/cycles.jpeg";
import cyclx from "../Assets/bby.png";
import vrsx from "../Assets/arvideos.png";
import vrsxx from "../Assets/launchg.png";
import runs from "../Assets/ruun.png";
import vrsxxx from "../Assets/wid.png";
import inno from "../Assets/road.png";
import what from "../Assets/activate.png";
import finger from "../Assets/vw.png";
import feel from "../Assets/powerss.png";
import puzz from "../Assets/puz.png";
import marketingfive from "../Assets/roadshow.mp4";
import iwdisplay from "../Assets/walls.mp4";
import bycle from "../Assets/cycli.mp4";
import hos from "../Assets/platnumz.mp4";
import runx from "../Assets/run.mp4";
import puzzle from "../Assets/puzzle.mp4";
export default function Roadshows() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust as needed
  });
  const [audioinput, setAudio] = useState(false);
  const [switchplay, setSwitchplay] = useState(true);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [switchone, setSwitchone] = useState(true);
  const [swichtwo, setSwitchtwo] = useState(true);
  const [switchthree, setSwitchthree] = useState(true);
  const [switchfour, setSwitchfour] = useState(true);
  const [switchfive, setSwitchfive] = useState(true);

  useEffect(() => {
    if (inView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [inView, hasAnimated]);

  const audioSwitch = () => {
    setAudio(!audioinput);
  };

  const switchPlays = () => {
    setSwitchplay(!switchplay);
  };

  const switchOne = () => {
    setSwitchone(!switchone);
  };

  const switchTwo = () => {
    setSwitchtwo(!swichtwo);
  };

  const switchThree = () => {
    setSwitchthree(!switchthree);
  };

  const switchFour = () => {
    setSwitchfour(!switchfour);
  };

  const switchFive = () => {
    setSwitchfive(!switchfive);
  };

  return (
    <div className="aboutpage">
      <div className="allpages">
        <Navigation />
        <div className="aboutcontents">
          <div className="aboutuso">
            <img className="scroll-image" src={inno} alt="#" loading="lazy" />
            <img className="scroll-image" src={what} alt="#" loading="lazy"/>
            {/* <img className="scroll-image" src={offer} alt="#" /> */}
          </div>
          <MousePointer />
          <div className="upperx">
          <NavLink to="/roadshows" className="sects">
              <p>Roadshow & Activations</p>
            </NavLink>
                     

            <NavLink to="/influencer" className="sects">
              <p>Influencer Management</p>
            </NavLink>

            <NavLink to="/hybrid-marketing" className="sects">
              <p className="planning">Hybrid Marketing</p>
            </NavLink>

            <NavLink to="/digitized-launch" className="sects">
              <p>Digitized Launch</p>
            </NavLink>

            <NavLink to="/trade-shows" className="sects">
              <p>Others</p>
            </NavLink>

            {/* <NavLink to="/trade-shows" className="sects">
              <p>Press Conference</p>
            </NavLink>
            <NavLink to="/trade-shows" className="sects">
              <p>Exhibitions & Tradeshows</p>
            </NavLink> */}
          </div>
          <div className="crcs"></div>
          <div className="middsx">
            <div className="concptt">
              <img src={concepts} alt="" />
            </div>

            <p className="concword">
              Take your brand directly to the streets with our dynamic roadshows
              and activations. Engage with your target audience in high-traffic
              areas, creating memorable moments and leaving a lasting
              impression.
            </p>

            <div className="concpt">
             <LazyImage src={conceptx} alt="" />
            </div>
          </div>

          <div className="lowersectx">
            <div className="concpteii">
             <img src={conceptxse} style={{ width: "200px" }} alt=""  loading="lazy"/>
            </div>

            <div className="augpics">
              <div className="picone">
                {switchone &&<LazyImage src={arx} alt="" />}
                <div className="cirdiv" onClick={switchOne}>
                 <LazyImage
                    src={finger}
                    style={{ width: "20px", height: "20px" }}
                    alt=""
                  />
                </div>

                {!switchone && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    playsInline
                    
                    onEnded={() => setSwitchone(true)}
                    className="centervideoxss"
                  >
                    <source src={marketingfive} type="video/mp4" />
                  </video>
                )}
              </div>

              <div className="picone">
                {swichtwo &&<LazyImage src={holo} alt="" />}
                <div className="cirdiv" onClick={switchTwo}>
                 <LazyImage
                    src={finger}
                    style={{ width: "20px", height: "20px" }}
                    alt=""
                  />
                </div>

                {!swichtwo && (
                  <video
                    autoPlay
                    // muted
                    // loop
                    
                    playsInline
                    onEnded={() => setSwitchtwo(true)}
                    className="centervideoxss"
                  >
                    <source src={hos} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";import LazyImage from "../LazyImage/LazyImage";
import * as motion from "motion/react-client";
import intro from "../Assets/themasai.mp4";
import welcox from "../Assets/mora.jpg";
import themasai from "../Assets/themasai.mp4";
import animvid from "../Assets/upperlines.mp4";
import contactus from "../Assets/contactus.png";
import phone from "../Assets/phone.png";
import location from "../Assets/location.png";
import house from "../Assets/houses.png";
import email from "../Assets/email.png";
import mobile from "../Assets/mobile.png";
import inno from "../Assets/contaxt.png";
import what from "../Assets/reachus.png";
import maps from "../Assets/map.png";

import phonex from "../Assets/phonex.png";
import name from "../Assets/name.png";
import emails from "../Assets/emails.png";
import pen from "../Assets/pen.png";
import subject from "../Assets/subject.png";

import coolp from "../Assets/coolproject.png";
import Navigation from "../Navigation/Navigates";
import MousePointer from "../Mousepointer/Mousepointer";

const MapComponent = () => (
  <div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7923.723914038557!2d39.274400973511526!3d-6.786647988011408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4d9a83e92a03%3A0xf0bb71d139a1263f!2sImperial%20Innovations!5e0!3m2!1sen!2stz!4v1739744065950!5m2!1sen!2stz"
      width="600"
      height="450"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
);

export default function Contact() {
  const [ended, setEnded] = useState(false);
  const [map, setMap] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Regex pattern for checking common mobile user agents
      const isMobileDevice =
        /android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ipad|ipod|iris|kindle|midp|mmp|mobile|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|silk|series(4|6)0|symbian|up\.browser|up\.link|vodafone|wap|windows ce|xda|xiino/i.test(
          userAgent
        );
      setIsMobile(isMobileDevice);
    };

    checkIfMobile();
  }, []);

  const mapShow = () => {
    setMap(!map);
  };

  return (
    <div className="homepages">
      {/* {!ended && (
        <video
          autoPlay
          muted
          loop={false}
          playsInline
          onEnded={() => setEnded(true)}
          className="background-video"
        >
          <source src={intro} type="video/mp4" />
        </video>
      )} */}

      {ended && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.9,
            scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
          }}
          className="background"
        >
         <img loading="lazy"  className="background-image" src={welcox} alt="#" />
          <div className="overlays"></div>
        </motion.div>
      )}
      <MousePointer />

      <div className="contactus">
        <div className="aboutusx">
         <img loading="lazy"  className="scroll-image" src={inno} alt="#" />
          {/* {!isMobile && (
           <img loading="lazy" 
              className="scroll-image"
              src={what}
              style={{
                width: "500px",
              }}
              alt="#"
            />
          )}

          {isMobile && (
           <img loading="lazy" 
              className="scroll-image"
              src={what}
              style={{
                width: "280px",
              }}
              alt="#"
            />
          )} */}

          {/*<img loading="lazy"  className="scroll-image" src={offer} alt="#" /> */}
        </div>

        <div className="haveacool">
          <div className="allincool">
           <img loading="lazy"  className="coolimg" src={coolp} alt="#" />
            <div className="details">
             <img loading="lazy"  className="phonx" src={phone} alt="#" />
              <div className="detailsnos">
                <div className="imgdet">
                 <img loading="lazy"  src={house} alt="#" />
                  <p> Ada Estate Block Number 13</p>
                </div>

                <div className="imgdet">
                 <img loading="lazy"  src={location} alt="#" />
                  <p> Ada Estate Street, Kinondoni</p>
                </div>

                <div className="imgdet">
                 <img loading="lazy"  src={email} alt="#" />
                  <p> md@imc.co.tz</p>
                </div>

                <div className="imgdet">
                 <img loading="lazy"  src={mobile} alt="#" />
                  <p> +255 713 525 152</p>
                </div>

                <div className="switchmap" onClick={mapShow}>
                 <img loading="lazy"  src={maps} alt="#" />
                </div>

                <div className="imgdets">
                  <p> Copyright© imc 2024</p>
                </div>
              </div>
            </div>
          </div>

          <div className="contaxts">
            <div className="datas">
             <img loading="lazy"  className="contactusx" src={name} alt="#" />
              <input type="text" placeholder="Name" />
            </div>
            <div className="datas">
             <img loading="lazy"  className="contactusx" src={phonex} alt="#" />
              <input type="text" placeholder="Phone" />
            </div>
            <div className="datas">
             <img loading="lazy"  className="contactusx" src={emails} alt="#" />
              <input type="text" placeholder="Email Address" />
            </div>
            <div className="datas">
             <img loading="lazy"  className="contactusx" src={subject} alt="#" />
              <input type="text" placeholder="Subject" />
            </div>
            <div className="datas">
             <img loading="lazy"  className="contactusx" src={pen} alt="#" />
              <input
                type="text"
                placeholder="What should we help to make you feel in touch?"
              />
            </div>

            <div className="getint">
              <button className="getintx">Get In Touch</button>
            </div>
          </div>
        </div>

        {map && (
          <div className="mapps">
            <MapComponent />
          </div>
        )}
      </div>

      <div className="allpages">
        <Navigation />
        <div className="contents"></div>
      </div>
    </div>
  );
}

// +255 713 525 152
// Ada Estate Street, Kinondoni
// Ada Estate Block Number 13
// md@imc.co.tz
// Copyright © IMC 2024

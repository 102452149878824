import React, { useState, useEffect, useRef } from "react";import LazyImage from "../LazyImage/LazyImage";
import intro from "../Assets/marketing.mp4";

import marketingtwo from "../Assets/vr.mp4";
import marketingthree from "../Assets/marketingthree.mp4";
import marketingfour from "../Assets/marketingfour.mp4";
import marketingfive from "../Assets/marketingfive.mp4";

import eventplan from "../Assets/eventplans.png";
import launchaction from "../Assets/launchaction.png";
import roadshows from "../Assets/roadshows.png";
// import innovation from "../Assets/we.mov";
import inno from "../Assets/imperial.png";
import arrowsx from "../Assets/touch.png";
import picone from "../Assets/picone.jpg";
import Navigation from "../Navigation/Navigates";
import MousePointer from "../Mousepointer/Mousepointer";
import capability from "../Assets/capabilities.png";

import facebook from "../Assets/facebook.png";
import pictwo from "../Assets/pictwo.jpg";
import picthree from "../Assets/picthree.jpg";

import one from "../Assets/one.png";
import two from "../Assets/two.png";
import three from "../Assets/three.png";

import welcox from "../Assets/mora.jpg";
import * as motion from "motion/react-client";

import soundFile from "../Assets/sweep.wav";
import soundFile2 from "../Assets/chip.wav";
import { NavLink } from "react-router-dom";

export default function Marketing() {
  const [spinning, setSpinning] = useState(false);
  const [spinone, setSpinone] = useState(false);
  const [spinthree, setSpinthree] = useState(false);
  const [insertpich, setInsertpich] = useState(true);

  const audioRef = useRef(new Audio(soundFile));
  const audioRefs = useRef(new Audio(soundFile2));
  const [menus, setMenu] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Regex pattern for checking common mobile user agents
      const isMobileDevice =
        /android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ipad|ipod|iris|kindle|midp|mmp|mobile|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|silk|series(4|6)0|symbian|up\.browser|up\.link|vodafone|wap|windows ce|xda|xiino/i.test(
          userAgent
        );
      setIsMobile(isMobileDevice);
    };

    checkIfMobile();
  }, []);

  const playSound = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  const switchingSpins = () => {
    playSound();
    setSpinning(true);
    setSpinthree(false);
    setSpinone(false);
    setInsertpich(false);
    window.scrollTo({ top: 0, behavior: "smooth" });

  };

  const switchingSpinone = () => {
    playSound();
    setSpinone(true);
    setSpinthree(false);
    setSpinning(false);
    setInsertpich(false);
    window.scrollTo({ top: 0, behavior: "smooth" });

  };

  const switchingSpinthree = () => {
    playSound();
    setSpinthree(true);
    setSpinning(false);
    setSpinone(false);
    setInsertpich(false);
    window.scrollTo({ top: 0, behavior: "smooth" });

  };

  const switchOff = () => {
    playSound();
    setSpinning(false);
    setSpinone(false);
    setSpinthree(false);
    setInsertpich(true);
    
  };

 

  return (
    <div className="marketpagex">
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.9,
          scale: { type: "spring", visualDuration: 2, bounce: 0.5 },
        }}
        className="background"
      >
        <img className="background-image" src={welcox} alt="#" loading="lazy"/>
        <div className="overlayx"></div>
      </motion.div>

      <MousePointer />
      <div className="allpages">
        <Navigation />
        {!isMobile && (
          <div className="contentsx">
            <div className="directarrow">
              <div className="onrow">
                <img className="scroll-image" src={inno} alt="#" loading="lazy"/>
              </div>

              <div className="tworow">
                <div
                  className="cardone"
                  style={{
                    position: "relative",
                    zIndex: "10000",
                  }}
                  onClick={switchingSpinone}
                >
                  <img
                    id="cardds"
                    src={one}
                    alt="#"
                    onClick={switchingSpinone}
                    loading="lazy"
                  />

                  <img
                    id="checkout"
                    src={picone}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="#"
                    loading="lazy"
                  />

                  <NavLink to="/experiential">
                    <div className="spears">
                      <p>Experiential Marketing</p>
                    </div>
                  </NavLink>

                  {spinone && (
                    <div className="boxxing">
                      {!spinone && (
                        <div className="arrodir">
                          <img src={arrowsx} alt="" loading="lazy"/>
                        </div>
                      )}
                    </div>
                  )}

                  {!spinone && (
                    <div className="boxxings">
                      <div className="arrodir">
                        <img src={arrowsx} alt="" loading="lazy"/>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="cardone"
                  style={{
                    position: "relative",
                    zIndex: "10000",
                  }}
                  onClick={switchingSpins}
                >
                  <img id="cardds" src={two} alt="#" onClick={switchingSpins} loading="lazy"/>

                  <img
                    id="checkout"
                    src={pictwo}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="#"
                    loading="lazy"
                  />

                  <div className="spearsx">
                    <p>imc@2025</p>
                  </div>

                  {spinning && (
                    <div className="boxxing">
                      {!spinning && (
                        <div className="arrodir" onClick={switchingSpins}>
                          <img src={arrowsx} alt="" loading="lazy"/>
                        </div>
                      )}
                    </div>
                  )}

                  {!spinning && (
                    <div className="boxxings">
                      <div className="arrodir" onClick={switchingSpins}>
                        <img src={arrowsx} alt="" loading="lazy"/>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="cardone"
                  style={{
                    position: "relative",
                    zIndex: "10000",
                  }}
                  onClick={switchingSpinthree}
                >
                  <img
                    id="cardds"
                    src={three}
                    alt="#"
                    onClick={switchingSpinthree}
                    loading="lazy"
                  />

                  <img
                    id="checkout"
                    src={picthree}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="#"
                    loading="lazy"
                  />
                  <NavLink to="/hybrid-marketing">
                    <div className="spears">
                      <p>Hybrid Marketing</p>
                    </div>
                  </NavLink>
                  {spinthree && (
                    <div className="boxxing">
                      {!spinthree && (
                        <div className="arrodir">
                          <img src={arrowsx} alt="" loading="lazy"/>
                        </div>
                      )}
                    </div>
                  )}

                  {!spinthree && (
                    <div className="boxxings">
                      <div className="arrodir">
                        <img src={arrowsx} alt="" loading="lazy"/>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="videosall">
              <div className="comb">
                {insertpich && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      loop
                      
                      playsInline
                      className="centervideox"
                    >
                      <source src={intro} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#" loading="lazy"/>
                    </div>
                  </div>
                )}

                {spinone && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      
                      loop
                      playsInline
                      onEnded={() => switchOff}
                      className="centervideox"
                    >
                      <source src={marketingfive} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#" loading="lazy"/>
                    </div>
                  </div>
                )}

                {spinthree && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      loop
                      playsInline
                      
                      onEnded={() => switchOff}
                      className="centervideox"
                    >
                      <source src={marketingtwo} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#"loading="lazy"/>
                    </div>
                  </div>
                )}

                {spinning && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      loop
                      playsInline
                      
                      onEnded={() => switchOff}
                      className="centervideox"
                    >
                      <source src={marketingfour} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#" loading="lazy"/>
                    </div>
                  </div>
                )}
              </div>

              <div className="aboutwd">
                <img className="clss" src={capability} alt="#" loading="lazy"/>
                <p>
                  Today, our capabilities have expanded significantly. We boast
                  in-house expertise in LED screens, cutting-edge sound systems,
                  dynamic light systems, and precision fabrication. Our
                  dedicated team excels in the art of conceptualization and
                  seamless operations, ensuring that every project we undertake
                  is a masterpiece in its own right.
                </p>
              </div>
            </div>

            {/* <div className="morehere" onClick={handleClick}>
            <div className="arrodirx">
              <img src={arrowsx} alt="" loading="lazy" />
            </div>
            <p>Explore</p>
          </div> */}
          </div>
        )}

        {isMobile && (
          <div className="contentsx">
            <div className="videosall">
              <div className="comb">
                {insertpich && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      loop
                      playsInline
                      
                      className="centervideox"
                    >
                      <source src={intro} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#" loading="lazy"/>
                    </div>
                  </div>
                )}

                {spinone && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      loop
                      playsInline
                      onEnded={() => switchOff}
                      className="centervideox"
                      
                    >
                      <source src={marketingfive} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#" loading="lazy"/>
                    </div>
                  </div>
                )}

                {spinthree && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      loop
                      
                      playsInline
                      onEnded={() => switchOff}
                      className="centervideox"
                    >
                      <source src={marketingtwo} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#" loading="lazy"/>
                    </div>
                  </div>
                )}

                {spinning && (
                  <div className="vidonesx">
                    <video
                      autoPlay
                      // muted
                      loop
                      
                      playsInline
                      onEnded={() => switchOff}
                      className="centervideox"
                    >
                      <source src={marketingfour} type="video/mp4" />
                    </video>

                    {/* */}
                    <div className="hover-text">
                      <img className="eventsx" src={eventplan} alt="#" loading="lazy"/>
                    </div>
                  </div>
                )}
              </div>

              <div className="aboutwd">
                <img className="clss" src={capability} alt="#" loading="lazy"/>
                <p>
                  Today, our capabilities have expanded significantly. We boast
                  in-house expertise in LED screens, cutting-edge sound systems,
                  dynamic light systems, and precision fabrication. Our
                  dedicated team excels in the art of conceptualization and
                  seamless operations, ensuring that every project we undertake
                  is a masterpiece in its own right.
                </p>
              </div>

              <NavLink to="/experiential" className="checkspears">
                <div className="spears">
                  <p>Experiential Marketing</p>
                </div>
              </NavLink>
 
            </div>

            <div className="directarrow">
              <div className="onrow">
                <img className="scroll-image" src={inno} alt="#" />
              </div>

              <div className="tworow">
                <div
                  className="cardone"
                  style={{
                    position: "relative",
                    zIndex: "10000",
                  }}
                  onClick={switchingSpinone}
                >
                  <img
                    id="cardds"
                    src={one}
                    alt="#"
                    loading="lazy"
                    onClick={switchingSpinone}
                  />

                  <img
                    id="checkout"
                    src={picone}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="#"
                  />

                

                  {spinone && (
                    <div className="boxxing">
                      {!spinone && (
                        <div className="arrodir">
                          {/* <img src={arrowsx} alt="" /> */}
                        </div>
                      )}
                    </div>
                  )}

                  {!spinone && (
                    <div className="boxxings">
                      <div className="arrodir">
                        {/* <img src={arrowsx} alt="" /> */}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="cardone"
                  style={{
                    position: "relative",
                    zIndex: "10000",
                  }}
                  onClick={switchingSpins}
                >
                  <img id="cardds" src={two} alt="#" onClick={switchingSpins} loading="lazy"/>

                  <img
                    id="checkout"
                    src={pictwo}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="#"
                    loading="lazy"
                  />

                

                  {spinning && (
                    <div className="boxxing">
                      {!spinning && (
                        <div className="arrodir" onClick={switchingSpins}>
                          {/* <img src={arrowsx} alt="" /> */}
                        </div>
                      )}
                    </div>
                  )}

                  {!spinning && (
                    <div className="boxxings">
                      <div className="arrodir" onClick={switchingSpins}>
                        {/* <img src={arrowsx} alt="" /> */}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="cardone"
                  style={{
                    position: "relative",
                    zIndex: "10000",
                  }}
                  onClick={switchingSpinthree}
                >
                  <img
                    id="cardds"
                    src={three}
                    alt="#"
                    onClick={switchingSpinthree}
                    loading="lazy"
                  />

                  <img
                    id="checkout"
                    src={picthree}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                    alt="#"
                    loading="lazy"
                  />

                  {spinthree && (
                    <div className="boxxing">
                      {!spinthree && (
                        <div className="arrodir">
                          {/* <img src={arrowsx} alt="" /> */}
                        </div>
                      )}
                    </div>
                  )}

                  {!spinthree && (
                    <div className="boxxings">
                      <div className="arrodir">
                        {/* <p>Home of Immersive Experiential Marketing</p> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="spearsx">
                    <p>imc@2025</p>
                  </div>

            {/* <div className="morehere" onClick={handleClick}>
            <div className="arrodirx">
              <img src={arrowsx} alt="" />
            </div>
            <p>Explore</p>
          </div> */}
          </div>
          
        )}
      </div>
    </div>
  );
}

import React from "react";
import intro from "../Assets/moranintro.mp4";
import { motion } from "motion/react"
import { useNavigate } from "react-router-dom";

export default function Landing() {
  const navigate = useNavigate();

  return (
    <div className="landpage">
    <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} className="videointro">
    <video autoPlay
        muted
        loop={false}  
        playsInline
        className="background-video"
        onEnded={() => navigate("/home")}>
        <source src={intro} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </motion.div>
     
    </div>
  );
}

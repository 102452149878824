import React, { useEffect, useState } from "react";import LazyImage from "../LazyImage/LazyImage";
import { NavLink } from "react-router-dom";
import Navigation from "../Navigation/Navigates";
import * as motion from "motion/react-client";
import { useInView } from "react-intersection-observer";
import conceptx from "../Assets/concs.png";
import concepts from "../Assets/hymark.png";
import conceptxs from "../Assets/virtualreality.png";
import MousePointer from "../Mousepointer/Mousepointer";
import conceptxse from "../Assets/augmrealitys.png";
import conceptxsei from "../Assets/games.png";
import holo from "../Assets/holo.png";
import holox from "../Assets/runimage.jpeg";
import arx from "../Assets/arx.jpeg";
import arsx from "../Assets/inter.jpeg";
import arsxa from "../Assets/puzzle.jpeg";
import vrs from "../Assets/vrhead.png";
import cycles from "../Assets/cycles.jpeg";
import cyclx from "../Assets/bby.png";
import vrsx from "../Assets/arvideos.png";
import vrsxx from "../Assets/launchg.png";
import runs from "../Assets/ruun.png";
import vrsxxx from "../Assets/wid.png";
import inno from "../Assets/hybrid.png";
import what from "../Assets/markts.png";
import finger from "../Assets/touchidd.png";
import feel from "../Assets/youtube.png";
import puzz from "../Assets/puz.png";
import marketingfive from "../Assets/marketingfive.mp4";
import iwdisplay from "../Assets/walls.mp4";
import bycle from "../Assets/cycli.mp4";
import hos from "../Assets/ho.mp4";
import runx from "../Assets/run.mp4";
import puzzle from "../Assets/puzzle.mp4";
export default function Other() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust as needed
  });
  const [audioinput, setAudio] = useState(false);
  const [switchplay, setSwitchplay] = useState(true);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [switchone, setSwitchone] = useState(true);
  const [swichtwo, setSwitchtwo] = useState(true);
  const [switchthree, setSwitchthree] = useState(true);
  const [switchfour, setSwitchfour] = useState(true);
  const [switchfive, setSwitchfive] = useState(true);

  useEffect(() => {
    if (inView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [inView, hasAnimated]);

  const audioSwitch = () => {
    setAudio(!audioinput);
  };

  const switchPlays = () => {
    setSwitchplay(!switchplay);
  };

  const switchOne = () => {
    setSwitchone(!switchone);
  };

  const switchTwo = () => {
    setSwitchtwo(!swichtwo);
  };

  const switchThree = () => {
    setSwitchthree(!switchthree);
  };

  const switchFour = () => {
    setSwitchfour(!switchfour);
  };

  const switchFive = () => {
    setSwitchfive(!switchfive);
  };
  const handleClick = () => {
    window.location.href = 'https://www.youtube.com/@imperialmarketingcommunica9365';
};
  return (
    <div className="aboutpage">
      <div className="allpages">
        <Navigation />
        <div className="aboutcontents">
   
          <MousePointer />
 

          <div className="lowersectx"  style={{marginTop: "145px"}}>
   
            {/* <div className="midbox"></div> */}
          
            <div className="concptdets">
              <div className="picsboxx">
                <img src={vrs} alt="" loading="lazy"/>
                <div className="vrra" onClick={handleClick}>
                  <img src={feel} style={{width: "80px", height:'80px'}}  alt="" loading="lazy" />
                </div>
              </div>

              <div className="moreabout" onClick={handleClick}>
                <p>Explore More</p>
              </div>          
           
          
            </div>
          </div>

 
 

          <div className="lowersectx">

          </div>
        </div>
      </div>
    </div>
  );
}

import React,{useEffect} from "react"; 
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Marketing from "./components/Marketing/Marketing";
import About from "./components/About/About";
import Landing from "./components/Landing/Landing";
import Multimedia from "./components/Multimedia/Multimedia";
import Contact from "./components/Contact/Contact";
import Fabrication from "./components/Fabrication/Fabrication";
import Experiential from "./components/Experiential/Experiential";
import Hybrid from "./components/Hybrid/Hybrid";
import Other from "./components/Other/Other";
import Influencer from "./components/Influencer/Influencer";
import Branding from "./components/Branding/Branding";
import Digitized from "./components/Digitized/Digitized";
import Roadshows from "./components/Roadshows/Roadshows";

function App() {
  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault(); // Disable right-click
    const handleCopy = (e) => e.preventDefault();        // Disable copy
    const handlePaste = (e) => e.preventDefault();       // Disable paste
    const handleCut = (e) => e.preventDefault();         // Disable cut
    const handleDrag = (e) => e.preventDefault();        // Disable drag

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);
    document.addEventListener('cut', handleCut);
    document.addEventListener('dragstart', handleDrag);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
      document.removeEventListener('cut', handleCut);
      document.removeEventListener('dragstart', handleDrag);
    };
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/fabrication" element={<Fabrication />} />
        <Route path="/home" element={<Home />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/media" element={<Multimedia />} />
        <Route path="/roadshows" element={<Roadshows />} />
        <Route path="/trade-shows" element={<Other />} />
        <Route path="/influencer" element={<Influencer />} />
        <Route path="/branding" element={<Branding />} />
        <Route path="/digitized-launch" element={<Digitized />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/experiential" element={<Experiential />} />
        <Route path="/hybrid-marketing" element={<Hybrid />} />
      </Routes>
    </Router>
  );
}

export default App;
